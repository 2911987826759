import { formatCurrency } from "common/utils/currencyFormater";
import { FC } from "react";

const PaymentOverview: FC<{
  donatedAmount: number;
  donationFee: number;
}> = ({ donatedAmount, donationFee }) => {
  const total = donatedAmount + donationFee;

  return (
    <div className="mt-5 border-t border-stroke-soft-200">
      <h2 className="mt-5 text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] text-neutral-900">
        Overview
      </h2>
      <div className="mt-4 flex justify-between">
        <h2 className="text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] text-neutral-900">
          Your donation
        </h2>
        <h2 className="text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] text-neutral-900">
          {formatCurrency(isNaN(donatedAmount) ? "0" : donatedAmount, true)}
        </h2>
      </div>
      <div className="mt-4 flex justify-between">
        <h2 className="text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] text-neutral-900">
          Payment gateway
        </h2>
        <h2 className="text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] text-neutral-900">
          ₦{formatCurrency(donationFee.toFixed(2)) ?? "0"}
        </h2>
      </div>
      <div className="mb-5 mt-5 border-t border-stroke-soft-200">
        <div className="mt-4 flex justify-between">
          <span className="text-body-xl font-[500] text-[#0F0F0F]">Total</span>
          <span className="text-body-xl font-[500] text-[#0F0F0F]">
            {formatCurrency(total.toFixed(2), true)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentOverview;
