import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals.js";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { kroRouter } from "router/router";
import useAuthStore from "common/store/useAuthStore";
import KroConstants from "common/constants/kroConstants";
import { H } from "highlight.run";

export const queryClient = new QueryClient();
const highlightProjectId = process.env.REACT_APP_HIGHLIGHT_IO_PROJECT_ID || "";

const AuthListener = () => {
  useAuthStore.subscribe((state, prevState) => {
    if (!state.isAuthenticated && prevState.isAuthenticated) {
      toast.error(KroConstants.AUTH_ERROR_MSG);
    }

    H.identify(state.user?.email ?? "", {
      id: state.user?.id ?? "",
      firstName: state.user?.firstName ?? "",
      organisationName: state.user?.organisationName ?? "",
      country: state.user?.country ?? "",
      adminFirstName: state.user?.adminFirstName ?? "",
      adminPhoneNumber: state.user?.adminPhoneNumber ?? "",
      isOrganisationRegistered: state.user?.isOrganisationRegistered ?? false,
      registrationNumber: state.user?.registrationNumber ?? "",
    });
  });
  return <></>;
};

//TODO; this is exposed
const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: "h4tmd612erfe672sfdfp412aidhmlnjcra8b",
    // key represents your internal user id, or the account id that
    // the user belongs to.
    // This could also be a cookie you generate for anonymous users.
    key: "key",
  },
};

H.init(highlightProjectId, {
  serviceName: "Giv",
  tracingOrigins: true,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      //TODO
    ],
  },
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      stacked={true}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    <SplitFactoryProvider config={sdkConfig}>
      <AuthListener />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={kroRouter} />
      </QueryClientProvider>
    </SplitFactoryProvider>
  </StrictMode>,
);

reportWebVitals();
