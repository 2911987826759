import useDiceBear from "common/hooks/useDiceBear";
import { getSessionUser } from "common/store/useAuthStore";
import { getCampaignDefaultImage } from "common/utils/getCampaignDefauiltImage";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { CampaignStatus } from "features/campaign/domain/models/updateCampaignStatusPayload";
import { useUpdateCampaignStatus } from "features/campaign/presentation/hooks/useUpdateStatusCampaign";
import { KroButton } from "features/components/buttons/kroButton";
import useKroCountdown from "features/components/countdown/kroCountdown";
import { KroCategoryBadge } from "features/components/display/kroBadges";
import KroText from "features/components/display/kroText";
import KroProgressBar, {
  variant,
} from "features/components/progress/kroProgressBar";
import { TabController } from "features/components/tab/kroTabBar";
import { FC, useState } from "react";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";

interface Media {
  type: "image" | "video";
  src: string;
}

const PreviewAndPublishTab: FC<{
  campaign: CampaignModelMapper | undefined;
  tabController: TabController;
}> = ({ campaign, tabController }) => {
  const media: { type: "image" | "video"; src: string }[] = [
    ...(campaign?.videoUrl
      ? [{ type: "video" as const, src: campaign.videoUrl }]
      : []),
    ...(campaign?.imageUrls?.map((image) => ({
      type: "image" as const,
      src: image,
    })) || []),
  ];
  const countdown = useKroCountdown(campaign?.endDate);
  const user = getSessionUser();
  const avatar = useDiceBear({ seed: user?.firstName ?? "Fundraiser" });
  // const { showSnackbar } = useSnackbar();

  const publishMutation = useUpdateCampaignStatus(
    (error: Error) => {
      toast.error(error.message);
    },
    (data: boolean) => {
      toast.success("Campaign Published Successfully!");
    },
  );

  const onHandlePublish = () => {
    const payload = {
      id: campaign?.id ?? "",
      status: CampaignStatus.PUBLISHED,
    };
    publishMutation.mutate(payload);
  };

  return (
    <>
      <header className="mt-[36px]">
        <h2 className="mb-2 text-left text-[24px] font-medium leading-[28.8px] tracking-[-0.005em] text-neutral-900">
          Preview Fundraiser
        </h2>
        <p className="text-left text-body-md font-normal leading-[22px] tracking-[-0.005em] text-neutral-500">
          Give your fundraiser another look before you launch it.
        </p>
      </header>
      <div className="item mb-[48px] mt-[36px] flex w-full flex-col justify-center gap-[24px] rounded-3xl border border-neutral-200 p-3 md:flex-row md:gap-[52.55px] md:p-6 lg:w-[1041.59px]">
        <div className="flex w-full items-center justify-center md:block">
          <MediaPreview
            Medias={media}
            campaignCategory={campaign?.category ?? ""}
          ></MediaPreview>
        </div>
        <div className="w-full self-start">
          <div className="mb-[19.11px]">
            <h3 className="mb-[9.55px] text-left text-[31.85px] font-semibold leading-[31.85px] tracking-[-0.005em] text-[#000929]">
              {campaign?.title}
            </h3>

            <div className="flex gap-2">
              <KroCategoryBadge category={campaign?.category ?? ""} />
              {/* <KroVerifiedBadge /> */}
            </div>
          </div>

          <div className="w-full">
            <div className="mb-[19.11px]">
              <h3 className="mb-[6.37px] text-left text-[15.92px] font-semibold leading-[2</div>5.48px] tracking-[-0.005em] text-[#000929]">
                About this fundraiser
              </h3>
              <KroText text={campaign?.story ?? ""} minWords={50}></KroText>
            </div>
            <div className="lg mb-[9.55px] flex items-center justify-between rounded-[12.74px] border-[0.8px] p-[9.55px]">
              <div className="flex items-center">
                <img
                  src={avatar}
                  alt="Avatar"
                  className="mr-[11.15px] h-[38.21px] w-[38.21px] rounded-full"
                />
                <p className="decoration-skip-ink-none text-left font-inter text-[16px] font-normal leading-[24px] tracking-[-0.011em] text-neutral-700 underline decoration-solid underline-offset-2">
                  {user?.organisationName ??
                    `${user?.firstName ?? "You"} ${user?.lastName ?? ""}`}{" "}
                  started this fundraiser
                </p>

                {/* <div className="flex-col">
                  <div className="flex items-center justify-start">
                    <h4 className="text-left font-inter text-[12.74px] font-medium leading-[19.11px] tracking-[-0.011em] text-stone-950">
                      {data?.nameOfBeneficiary ?? "N/A"}
                    </h4>
                    <img
                      src="/assets/icons/verified-badge.svg"
                      alt="verified"
                      height="20.7px"
                      width="20.7px"
                    />
                  </div>
                  <p className="text-left font-inter text-[11.15px] font-normal leading-[15.92px] tracking-[-0.006em] text-sub-600">
                    {data?.beneficiary ?? "N/A"}
                  </p>
                </div> */}
              </div>

              {/* <div className="flex items-center justify-center rounded-[6.36px] border-[0.8px] px-[9.55px] py-[6.37px] font-inter text-[11.15px] font-medium leading-[15.92px] tracking-[-0.006em] text-sub-600">
                Fundraiser
              </div> */}
            </div>
            <div className="border-neutral-200p mb-[19.11px] rounded-[12.74px] border-[0.8px] px-[9.55px] py-[15.92px]">
              <div className="mb-[10px] flex flex-row items-center justify-between">
                <span className="text-body-lg font-semibold leading-[20px] tracking-[-0.006em] text-neutral-900">
                  {campaign?.targetAmount !== 0 && (
                    <span>₦{campaign?.amountRaised}</span>
                  )}{" "}
                  <span className="font-inter text-body-md font-normal text-neutral-600">
                    {campaign?.targetAmount !== 0 ? "raised of" : "raised"}
                  </span>{" "}
                  {campaign?.targetAmount !== undefined && (
                    <span className="text-body-md font-normal text-neutral-600">
                      ₦{campaign?.amountRaised}
                    </span>
                  )}
                </span>
                <span className="text-left font-inter text-[11.15px] font-medium leading-[12.74px] text-[#09101D]">
                  {countdown}
                </span>
              </div>
              <KroProgressBar
                height="h-3"
                customStyle={"mb-[12.74px]"}
                amountRaised={campaign?.amountRaised.toString() ?? ""}
                varient={variant.gradient}
                amountToBeRasied={campaign?.targetAmount?.toString() ?? ""}
              />
              <div className="flex flex-row justify-between">
                <div className="flex items-center">
                  {/* <div className="flex -space-x-1">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <GenerateAvatar key={index} index={index} />
                    ))}
                  </div> */}
                  <span className="ml-2 text-neutral-400">0 Donors</span>
                </div>
              </div>
            </div>
            <KroButton
              className="mb-[12.74px] w-full"
              title="Donate"
              animated={false}
            ></KroButton>
            <div className="flex">
              <button className="decoration-skip-ink-none flex w-full items-center justify-center gap-1 rounded-[27.63px] border border-neutral-200 bg-neutral-50 py-[10.23px] text-center font-inter text-[15px] font-medium leading-[24px] tracking-[-0.005em] text-[#0F0F0F]">
                Share
                <img src="/assets/icons/share-fill.svg" alt="Share" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[84px] flex gap-4">
        {/* <button
          type="reset"
          onClick={() => {}}
          className="max-w-[120px] rounded-[32px] border border-neutral-200 bg-neutral-50 px-[29px] py-[14px] text-center font-inter text-[16px] font-medium leading-[24px] tracking-[-0.005em] text-[#0F0F0F] hover:bg-neutral-100"
        >
          Discard
        </button> */}
        <KroButton.Base
          type="submit"
          onclick={() => onHandlePublish()}
          isLoading={publishMutation.isPending}
          hasIcon={publishMutation.isPending}
          className="px-[29px] py-[14px]"
          isDisabled={
            campaign?.title === null ||
            campaign?.targetAmount === null ||
            campaign?.description === null ||
            campaign?.startDate === null ||
            campaign?.endDate === null ||
            campaign?.category === null ||
            campaign?.visibility === null ||
            campaign?.story === null
          }
          title="Publish"
        />
      </div>
    </>
  );
};

const MediaPreview = ({
  Medias,
  campaignCategory,
}: {
  Medias: Media[];
  campaignCategory: string;
}) => {
  const [isSelected, setIsSelected] = useState<{
    index: number;
    data: Media;
  } | null>({ data: Medias[0], index: 0 });

  if (Medias.length === 0) {
    return (
      <div className="h-full max-h-[475.3px] w-full max-w-[517.49px] overflow-hidden rounded-[12px] border-neutral-200 bg-white">
        <img
          src={getCampaignDefaultImage(campaignCategory as string)}
          alt="Default Campaign"
          className="h-full w-full object-cover"
        />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col">
      {/* Main Selected Image/Video Container */}
      <div className="mb-4 w-full">
        {isSelected && isSelected.data && isSelected.data.type === "video" ? (
          <div className="relative h-[240px] w-full overflow-hidden rounded-xl bg-black md:h-[382.15px] md:w-[517.49px]">
            <ReactPlayer
              url={isSelected.data.src}
              controls
              width="100%"
              height="100%"
              className="rounded-xl"
            />
          </div>
        ) : isSelected && isSelected.data ? (
          <img
            src={isSelected.data.src}
            alt="Main Header"
            className="h-[240px] w-full rounded-xl object-cover md:h-[382.15px] md:w-[517.49px]"
          />
        ) : (
          <img
            src="/assets/images/placeHolder.svg"
            alt="Main Header"
            className="h-[240px] w-full rounded-xl object-cover md:h-[382.15px] md:w-[517.49px]"
          />
        )}
      </div>

      {/* Thumbnail Grid */}
      <div className="no-scrollbar flex w-full gap-2 overflow-x-auto pb-2 md:gap-4">
        {Array.from({ length: Medias.length > 5 ? Medias.length : 5 }).map(
          (_, index) => {
            const mediaItem = Medias[index];
            const isSelectedMedia = isSelected?.index === index;

            return (
              <div
                key={index}
                className={`flex-shrink-0 cursor-pointer rounded-lg ${
                  isSelectedMedia ? "" : ""
                }`}
                onClick={() => {
                  setIsSelected({ data: mediaItem ?? null, index: index });
                }}
              >
                {mediaItem?.type === "video" ? (
                  <div className="h-[60px] w-[60px] overflow-hidden rounded-lg md:h-[80px] md:w-[80px]">
                    <ReactPlayer
                      playing={false}
                      light={true}
                      url={mediaItem.src}
                      width="100%"
                      height="100%"
                      className="pointer-events-none"
                    />
                  </div>
                ) : (
                  <img
                    src={mediaItem?.src ?? "/assets/images/placeHolder.svg"}
                    alt={`Thumbnail ${index + 1}`}
                    className="h-[60px] w-[60px] rounded-lg object-cover md:h-[80px] md:w-[80px]"
                  />
                )}
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};
export default PreviewAndPublishTab;
