import KroCarousel from "features/components/display/KroCarousel";

import { FC, useState } from "react";
import CampaignCard from "../components/dashboard/campaignCard";
import SectionHeader from "../components/dashboard/sectionHeader";
import CustomButton from "../components/dashboard/customButton";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Routes from "router/routes";
import useFetchUserCampaigns from "../hooks/useFetchUserCampaign";
import { KroButton } from "features/components/buttons/kroButton";
import { useFetchCampaignCategories } from "../hooks/useFetchCampaignCategories";
import useFetchCampaigns from "../hooks/useFetchCampaings";
import { CampaignCategoryModel } from "features/campaign/domain/models/campaignCategoryModel";
import { toast } from "react-toastify";

const DashBoardHomePage: FC = function DashBoardHomePage() {
  return (
    <div className="mx-auto mt-[61px] max-w-[1290px]">
      <>
        <KroCarousel images={["", ""]} />
        <MyCampaigns />
        <DiscoverCampaigns />
      </>
    </div>
  );
};

export default DashBoardHomePage;

const MyCampaigns: FC = function MyCampaigns() {
  const navigate = useNavigate();

  // const { showActionSnackbar } = useSnackbar();

  const {
    isLoading: isLoadingUsersCampaigns,
    data: usersCampaigns,
    refetch: refetchUsersCampaigns,
    error: errorLoadingCampaigns,
  } = useFetchUserCampaigns();

  if (errorLoadingCampaigns) {
    toast.error(
      ({ closeToast }) => (
        <div className="flex items-center justify-between gap-2">
          <span>{errorLoadingCampaigns.message}</span>
          <span
            onClick={() => {
              refetchUsersCampaigns();
              closeToast();
            }}
            className="text-sm cursor-pointer text-black underline hover:text-gray-700"
          >
            Reload
          </span>
        </div>
      ),
      {
        autoClose: false,
        closeOnClick: false,
      },
    );
  }

  return (
    <div className="w-ful mt-[48px]">
      <SectionHeader title="My Campaigns">
        <CustomButton label="See all" />
      </SectionHeader>

      {!isLoadingUsersCampaigns &&
      (!usersCampaigns || usersCampaigns.length === 0) ? (
        <div className="relative flex h-[254px] w-full items-center justify-center rounded-3xl border border-[#E5E5E5]">
          <img
            src="assets/images/empty_campaign.svg"
            alt="empty campaign"
            className="absolute right-0 z-0"
          />
          <div className="rel z-10 flex flex-col items-center">
            <div className="mb-[14px]">
              <h3 className="text-center font-inter text-[20px] font-medium leading-[24px] tracking-[-0.011em] text-strong-950">
                Oops! No campaigns here yet.
              </h3>
              <p className="text-center font-inter text-[14px] font-normal leading-[20px] tracking-[-0.006em] text-sub-600">
                Start a new campaign
              </p>
            </div>
            <KroButton
              onclick={() => navigate(Routes.CREATE_CAMPAIGN)}
              title={"Start fundraiser"}
            ></KroButton>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 place-items-center gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {isLoadingUsersCampaigns
            ? [...Array(4)].map((_, index) => (
                <CampaignCard.Skeleton key={index} />
              ))
            : [...(usersCampaigns ?? [])].reverse().map((campaign, index) => (
                <CampaignCard
                  key={index}
                  data={campaign}
                  onViewClick={() =>
                    campaign.id
                      ? navigate(Routes.END_CAMPAIGN(campaign.id), {
                          state: campaign,
                        })
                      : null
                  }
                />
              ))}
        </div>
      )}
    </div>
  );
};

const DiscoverCampaigns: FC = () => {
  const { categories, isLoading, error, refetch } =
    useFetchCampaignCategories();

  const navigate = useNavigate();

  const {
    campaigns,
    isLoading: isLoadingCampaign,
    error: errorLoadingCampaigns,
    refetch: refetchCampaigns,
  } = useFetchCampaigns();
  // const { showActionSnackbar } = useSnackbar();
  if (error || errorLoadingCampaigns) {
    toast.error(
      ({ closeToast }) => (
        <div className="flex items-center justify-between gap-2">
          <span>{error?.message ?? errorLoadingCampaigns?.message ?? ""}</span>
          <span
            onClick={() => {
              refetch ?? refetchCampaigns();
              closeToast();
            }}
            className="text-sm cursor-pointer text-black underline hover:text-gray-700"
          >
            Reload
          </span>
        </div>
      ),
      {
        autoClose: false,
        closeOnClick: false,
      },
    );
  }

  const [selectedCategory, setSelectedCategory] =
    useState<CampaignCategoryModel | null>(null);
  return (
    <div className="mt-[110px] w-full">
      <SectionHeader title="Discover campaigns" />

      <div className="flex flex-col items-end justify-between gap-5 sm:gap-0">
        <div className="flex flex-wrap gap-4">
          {isLoading
            ? [...Array(4)].map((_, index) => (
                <CustomButton.Skeleton key={index} />
              ))
            : categories?.map((category, index) => (
                <CustomButton
                  label={category.name}
                  key={index}
                  isSelected={
                    selectedCategory
                      ? selectedCategory._id === category._id
                      : undefined
                  }
                  onClick={() => setSelectedCategory(category)}
                />
              ))}
        </div>
        <div className="flex items-center gap-3">
          <span className="font-inter text-body-lg font-normal leading-24 tracking-ultralTight text-[#404040]">
            Sort by:
          </span>
          <div className="relative">
            <button className="flex h-[52px] items-center justify-center gap-1 rounded-[32px] border border-[#E5E5E5] bg-neutral-50 px-6 py-[13.4px] font-inter text-body-lg font-medium leading-24 tracking-ultralTight text-[#0F0F0F] hover:bg-neutral-200">
              Just Launched{" "}
              <ChevronDownIcon
                className={`h-5 w-5 text-gray-500 transition-transform`}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-[36px] grid grid-cols-1 place-items-center gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {isLoadingCampaign
          ? [...Array(10)].map((_, index) => (
              <CampaignCard.Skeleton key={index} />
            ))
          : [...(campaigns ?? [])]
              ?.reverse()
              .map((campaign, index) => (
                <CampaignCard
                  key={index}
                  buttonTitle="Donate"
                  data={campaign}
                  onViewClick={() =>
                    campaign.id
                      ? navigate(Routes.CAMPAIGN_DETAILS(campaign.id))
                      : null
                  }
                />
              ))}
      </div>
    </div>
  );
};
