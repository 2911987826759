import { useForm } from "@tanstack/react-form";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import DurationSelector from "features/campaign/presentation/components/campaignCreate/campaignDuration";
import FundraiserTarget from "features/campaign/presentation/components/campaignCreate/fundraiserTarget";
import SupportingDocuments from "features/campaign/presentation/components/campaignCreate/supportingDocuments";
import { useKroFee } from "features/campaign/presentation/hooks/useKroFee";
import KroContainer from "features/components/display/kroContainer";
import KroLoadingIndicator from "features/components/loader/kroCircularIndicator";
import { TabController } from "features/components/tab/kroTabBar";
import { FC, useEffect } from "react";
import moment from "moment";
import useFormHandler from "../../hooks/useFormHandler";
import { KroButton } from "features/components/buttons/kroButton";
import { useUpdateCampaignFundraiserDetails } from "features/campaign/presentation/hooks/useUpdateCampaignFundraiserDetails";
import { queryClient } from "index";
import { toast } from "react-toastify";

const FundingTargetTab: FC<{
  campaign: CampaignModelMapper | undefined;
  tabController: TabController;
}> = ({ campaign, tabController }) => {
  const {
    isLoading: isKroFeeLoading,
    data: kroFee,
    error: errorLoadingKroFee,
  } = useKroFee();
  // const { showSnackbar } = useSnackbar();

  const hasNoTarget =
    campaign?.targetAmount === 0 || campaign?.targetAmount === null;

  const form = useForm({
    defaultValues: {
      targetAmount: campaign?.targetAmount?.toString() ?? "",
      amountToReceive: "",
      hasNoTarget: hasNoTarget,
      endDate: campaign?.endDate
        ? new Date(campaign?.endDate).toISOString()
        : "",
      supportingDocument: null as File | null,
    },
    onSubmit: ({ value }) => {
      if (campaign?.status === "ended") {
        toast.warning("Campaign cannot be updated as it has ended");
        form.reset();
        return;
      }
      const payload = {
        fundingPayload: {
          id: campaign?.id ?? "",
          amount: value.hasNoTarget ? 0 : parseFloat(value.targetAmount),
        },
        durationPayload: {
          id: campaign?.id ?? "",
          startDate: moment().format("YYYY-MM-DD"),
          endDate: value.endDate,
        },
        files: value.supportingDocument ? [value.supportingDocument] : [],
      };

      updateFundraiserTargetMutation.mutate({
        fundingPayload: payload.fundingPayload,
        durationPayload: payload.durationPayload,
        files: payload.files,
      });
    },
  });
  useFormHandler(form, tabController);

  useEffect(() => {
    if (kroFee) {
      if (!isNaN(campaign?.targetAmount ?? 0)) {
        const fee = (campaign?.targetAmount ?? 0) * (kroFee.percentage / 100);
        const amountToReceive = (campaign?.targetAmount ?? 0) - fee;
        form.setFieldValue("amountToReceive", amountToReceive.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFundraiserTargetMutation = useUpdateCampaignFundraiserDetails(
    (error: Error) => {
      toast.error(error.message);
    },
    (_, __) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchCampaign", campaign?.id],
      });
      toast.success("Campaign updated successfully");
    },
  );

  if (errorLoadingKroFee) {
    return (
      <KroLoadingIndicator
        error={errorLoadingKroFee?.message}
        onRetry={() => window.location.reload()}
      />
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      className="mt-9 max-w-[500px] justify-center"
    >
      <KroContainer
        isLoading={isKroFeeLoading}
        margin="mb-6 mt-[12px]"
        title={"Fundraiser target"}
      >
        <FundraiserTarget kroFee={kroFee} form={form} />
      </KroContainer>
      <KroContainer isLoading={isKroFeeLoading}>
        <DurationSelector form={form} autoOpenCalender={false} />
        <SupportingDocuments form={form} />
      </KroContainer>
      <div className="mt-[84px] flex gap-4">
        <button
          type="reset"
          onClick={() => form.reset()}
          className="max-w-[120px] rounded-[32px] border border-neutral-200 bg-neutral-50 px-[29px] py-[14px] text-center font-inter text-[16px] font-medium leading-[24px] tracking-[-0.005em] text-[#0F0F0F] hover:bg-neutral-100"
        >
          Discard
        </button>
        <KroButton.Base
          isDisabled={isKroFeeLoading}
          isLoading={updateFundraiserTargetMutation.isPending}
          type="submit"
          className="px-[29px] py-[14px]"
          hasIcon={updateFundraiserTargetMutation.isPending}
          title="Save Changes"
        />
      </div>
    </form>
  );
};

export default FundingTargetTab;
