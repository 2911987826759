import { KroButton } from "features/components/buttons/kroButton";
import KroModal from "features/components/modals/kroModal";
import React from "react";

interface WithdrawalErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WithdrawalErrorModal: React.FC<WithdrawalErrorModalProps> = ({
  isOpen,
  onClose,
}) => {
  const handleNavigate = () => {
    onClose();
    return;
  };

  return (
    <KroModal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={476}
      closeOnOutsideClick={false}
      showCloseButton={true}
      className="p-3"
    >
      <div className="inline-flex w-full flex-col items-center justify-stretch gap-8 p-6">
        <img src="/assets/icons/error 2.svg" alt="error" />

        <div className="flex flex-col items-center justify-center gap-2 self-stretch">
          <div className="text-[32px] font-medium leading-[38.40px] text-[#0f0f0f]">
            Withdrawal Failed!
          </div>
          <div className="text-sm leading-snug h-11 text-center font-normal text-neutral-500">
            Your withdrawal attempt was unsuccessful. Please try again.
          </div>
        </div>
        <div className="mt-8 flex w-full flex-col items-stretch justify-stretch">
          <KroButton
            title={"Continue"}
            type="submit"
            classes="w-full"
            onclick={handleNavigate}
          />
        </div>
      </div>
    </KroModal>
  );
};

export default WithdrawalErrorModal;
