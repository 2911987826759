import { useMutation } from "@tanstack/react-query";
import { CommentPayload } from "features/campaign/domain/models/commentPayload";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";

const campaignService = new CampaignServiceImpl();

export const useCreateComment = (
  onError:
    | ((
        error: Error,
        variables: CommentPayload,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
  onSuccess:
    | ((
        data: Promise<unknown> | unknown,
        variables: CommentPayload,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
) => {
  return useMutation({
    mutationFn: (payload: CommentPayload) =>
      campaignService.createComment(payload),
    onError: onError,
    onSuccess: onSuccess,
  });
};
