import KroClient from "common/io/client/kroClient";
import { KroEndpoints } from "common/io/endpoints";
import {
  CampaignModelMapper,
  mapCampaignModel,
} from "features/campaign/domain/models/campaignModel";
import { WithdrawalBanksResponse } from "../model/bankModel";
import {
  BankAccountDetailsResponse,
  BankAccountModel,
  BankAccountPayload,
  mapToBankAccountModel,
} from "../model/bankAccountModel";
import { WithdrawDonationPayload } from "../model/withdrawDonationPayload";
import { SayThanksPayload } from "../model/sayThanksPayload";
import {
  mapSayThanksResponse,
  SayThanksResponseMapper,
} from "../model/sayThanksResonse";

const DashboardRepository = () => {
  const fetchUserCampaigns = async (): Promise<Array<CampaignModelMapper>> => {
    try {
      const response = await KroClient.get(KroEndpoints.fetchUserCampaigns);
      return response.data.map((campaign: any) => mapCampaignModel(campaign));
    } catch (error) {
      throw error;
    }
  };

  const fetchCampaigns = async (): Promise<Array<CampaignModelMapper>> => {
    try {
      const response = await KroClient.get(KroEndpoints.fetchCampaigns);
      return response.data.map((campaign: any) => mapCampaignModel(campaign));
    } catch (error) {
      throw error;
    }
  };
  const fetchWithdrawalBanks = async (): Promise<
    Array<WithdrawalBanksResponse>
  > => {
    try {
      const response = await fetch(KroEndpoints.withdrawalFetchBanks);
      const data = await response.json();
      return data["banks"];
    } catch (error) {
      throw error;
    }
  };

  const fetchBankAccountInformation = async (
    payload: BankAccountPayload,
  ): Promise<BankAccountModel> => {
    const response = await fetch(KroEndpoints.fetchBankAccountInformation, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accountNumber: payload.accountNumber,
        accountBank: payload.bankCode,
      }),
    });
    const data = await response.json();

    console.log(data);

    if (
      Object.keys(data).length === 0 ||
      data['accountName'] === null || 
      data['accountName'] === undefined || 
      data['accountName'] === '' || 
      data['accountName'] === 'NA') {
      throw new Error('Account Details not found, Please check the account number and bank name');
    }
    return mapToBankAccountModel(data as BankAccountDetailsResponse);
  };

  const withdrawCampaignDonation = async (
    payload: WithdrawDonationPayload,
  ): Promise<boolean> => {
    const response = await KroClient.post(
      KroEndpoints.withdrawCampaignDonations,
      payload,
    );
    return response.data;
  };

  const fetchActivityLog = async (campaignId: string): Promise<[]> => {
    try {
      const response = await KroClient.get(
        KroEndpoints.fetchActivityLog(campaignId),
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const sayThanks = async (
    payload: SayThanksPayload,
  ): Promise<SayThanksResponseMapper> => {
    try {
      const response = await KroClient.post(
        KroEndpoints.sayThanks(payload.activityId),
        {
          message: payload.message,
        },
      );

      if (!response || !response.data) {
        throw new Error("An Error Occurred");
      }

      console.log(response.data);
      const mappedResponse = mapSayThanksResponse(response.data);
      return mappedResponse;
    } catch (error) {
      throw error;
    }
  };

  return {
    fetchUserCampaigns,
    withdrawCampaignDonation,
    sayThanks,
    fetchCampaigns,
    fetchBankAccountInformation,
    fetchWithdrawalBanks,
    fetchActivityLog,
  };
};

export default DashboardRepository;
