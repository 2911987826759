import React from "react";

interface AboutUsProps {
  about: string;
}

export const AboutUsComponent = (props: AboutUsProps) => {
  const { about } = props;

  return (
    <div className="lg:w-[773px]">
      <h3 className="text-body-lg font-bold text-black">About This Campaign</h3>
      <p className="mt-2 text-justify text-body-md font-normal text-[#000929]">
        <div dangerouslySetInnerHTML={{ __html: about }} />
      </p>

      {/* <FundRaiserAndBeneficiaryInformationComponent /> */}
    </div>
  );
};
