import Validation from "common/utils/validation";
import { useForm } from "@tanstack/react-form";
import { KroButton } from "features/components/buttons/kroButton";
import KroOTPBox from "features/components/inputs/kroOTPBox";
import KroModal from "features/components/modals/kroModal";
import { useSendOTP, useVerifyOTP } from "../../hooks/useOTP";
import OTPResendCountDown from "../resendOTPCountDown";
import kroConstants from "common/constants/kroConstants";
import { EOTPUseCases } from "features/onboarding/domain/enum/otpUseCase";
import { toast } from "react-toastify";

const PhoneVerificationModal: React.FC<{
  phone: string;
  isOpen: boolean;
  type: EOTPUseCases;
  onClose: (isVerified: boolean) => void;
}> = ({ phone, isOpen, onClose, type }) => {
  // const { showSnackbar } = useSnackbar();
  interface VerificationFormInputs {
    otp: string;
  }

  const form = useForm<VerificationFormInputs>({
    defaultValues: {
      otp: "",
    },

    onSubmit: (values) => {
      mutationVerifyOTP.mutate(values.value.otp);
    },
  });

  const mutationVerifyOTP = useVerifyOTP(
    (error: Error) => {
      toast.error(error.message);
    },
    (data: any) => {
      handleClose(true);
    },
  );

  const mutateOTPRequest = useSendOTP(
    (error: Error) => toast.error(error.message),
    () => {},
  );

  const handleClose = (isVerified: boolean) => {
    form.reset();
    mutationVerifyOTP.reset();
    mutateOTPRequest.reset();
    onClose(isVerified);
  };

  return (
    <KroModal
      maxWidth={500}
      className="p-0"
      closeOnOutsideClick={false}
      showCloseButton={false}
      isOpen={isOpen}
      onClose={() => handleClose(false)}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        className="m-2 flex flex-col items-center p-6 sm:m-3"
      >
        <img src="/assets/icons/otp.svg" alt="phone icon" className="mb-8" />
        <h2 className="mb-2 font-['Inter'] text-[32px] font-medium leading-[38.4px] tracking-[-0.005em]">
          Verification code
        </h2>

        <p className="text-sm mb-1 text-center font-['Inter'] text-[14px] font-[400] leading-[22px] tracking-[-0.005em] text-neutral-500">
          We’ve sent a 6-character code to{" "}
          <strong className="font-medium text-neutral-900">{phone}</strong>. The
          code expires shortly, so please enter it soon.
        </p>

        <div className="mb-8 flex flex-col gap-1">
          <div
            className="flex h-[38px] cursor-pointer items-center justify-center text-[#C56A09]"
            onClick={() => handleClose(false)}
          >
            Change phone number
          </div>
          <div className="flex h-[38px] items-center justify-center text-[#C56A09]">
            <OTPResendCountDown
              minutes={kroConstants.OTP_REQUEST_TIMEOUT}
              onResend={() =>
                mutateOTPRequest.mutateAsync({
                  phone: phone,
                  use_case: type,
                })
              }
            />
          </div>
        </div>

        <form.Field
          validators={{
            onChange: ({ value }) => {
              return Validation.minLength(value, kroConstants.OTP_LENGTH);
            },
          }}
          name="otp"
          children={(field) => {
            return (
              <KroOTPBox
                errorMessage={mutationVerifyOTP.error?.message}
                length={kroConstants.OTP_LENGTH}
                value={field.state.value}
                isInvalid={field.state.meta.errors.join(", ").trim() !== ""}
                onChange={(value) => field.handleChange(value)}
              ></KroOTPBox>
            );
          }}
        ></form.Field>

        <KroButton
          className="mt-8 w-full"
          isLoading={mutationVerifyOTP.isPending}
          title="Verify Phone"
          onclick={form.handleSubmit}
        ></KroButton>
      </form>
    </KroModal>
  );
};

export default PhoneVerificationModal;
