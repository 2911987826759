import { PaymentMethodsModel } from "features/campaign/domain/models/paymentMethodsModel";
import { campaignDonateForm } from "../../forms/campaignDonateForm";
import { FC, useState } from "react";
import Validation from "common/utils/validation";
import { FieldApi } from "@tanstack/react-form";
import { scrollToFieldError } from "common/utils/scrollTo";

interface PaymentMethodsProps {
  form: campaignDonateForm;
}

const PaymentMethods: FC<PaymentMethodsProps> = ({ form }) => {
  const [selectedMethod, setSelectedMethod] = useState<string>("");

  const pMethods: PaymentMethodsModel[] = [
    {
      iconPath: "/assets/icons/paystack.svg",
      name: "Pay with Paystack",
    },
    {
      iconPath: "/assets/icons/bank-transfer.svg",
      name: "Bank Transfer",
    },
  ];

  const handleMethodChange = (method: string, field: FieldApi<any, any>) => {
    setSelectedMethod(method);
    field.handleChange(method);
  };

  return (
    <form.Field
      name="paymentMethod"
      validators={{
        onChange: ({ value }) => Validation.required(value),
      }}
      children={(field) => {
        scrollToFieldError(field);

        const errorMessage = field.state.meta.errors.join(", ");

        return (
          <div id={field.name} className="mt-4 border-t border-stroke-soft-200">
            <h2 className="mt-5 text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] text-neutral-900">
              Payment Method
              {errorMessage && (
                <span className="text-left italic text-red-500">
                  {" " + errorMessage}
                </span>
              )}
            </h2>
            {pMethods.map((pMethod) => (
              <PaymentMethodsItem
                key={pMethod.name}
                iconPath={pMethod.iconPath}
                name={pMethod.name}
                isSelected={selectedMethod === pMethod.name}
                onChange={() => handleMethodChange(pMethod.name, field)}
              />
            ))}
          </div>
        );
      }}
    />
  );
};

interface PaymentMethodsItemProps extends PaymentMethodsModel {
  isSelected: boolean;
  onChange: () => void;
}

const PaymentMethodsItem: FC<PaymentMethodsItemProps> = ({
  iconPath,
  name,
  isSelected,
  onChange,
}) => {
  return (
    <label
      className="mt-3 flex cursor-pointer items-center justify-between rounded-3xl border border-neutral-200 p-3 hover:bg-neutral-50"
      onClick={onChange}
    >
      <div className="flex items-center">
        <img src={iconPath} alt={name} className="mr-3" />
        <div className="text-lg font-medium text-neutral-900">{name}</div>
      </div>
      <input
        className="h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 checked:border-neutral-200 checked:bg-primary"
        type="radio"
        name="paymentMethod"
        checked={isSelected}
        onChange={onChange}
      />
    </label>
  );
};

export default PaymentMethods;
