import Validation from "common/utils/validation";
import { useForm } from "@tanstack/react-form";
import { KroButton } from "features/components/buttons/kroButton";
import KroOTPBox from "features/components/inputs/kroOTPBox";
import KroModal from "features/components/modals/kroModal";
import KroConstants from "common/constants/kroConstants";
import { EOTPUseCases } from "features/onboarding/domain/enum/otpUseCase";
import OTPResendCountDown from "features/onboarding/presentation/components/resendOTPCountDown";
import {
  useSendOTP,
  useVerifyOTP,
} from "features/onboarding/presentation/hooks/useOTP";
import { WithdrawDonationModel } from "features/dashboard/domain/model/withdrawDonationPayload";
import useWithdrawCampaignDonation from "../../hooks/useWithdrawCampaignDonation";
import { useEffect } from "react";
import { toast } from "react-toastify";

interface VerificationFormInputs {
  otp: string;
}

const WithdrawalAuthenticationModal: React.FC<{
  email: string;
  isOpen: boolean;
  payLoad: WithdrawDonationModel;
  type: EOTPUseCases;
  onClose: (isCompleted: boolean, error?: string) => void;
}> = ({ email, isOpen, onClose, type, payLoad }) => {
  useEffect(() => {
    form.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const { showSnackbar } = useSnackbar();

  const form = useForm<VerificationFormInputs>({
    defaultValues: {
      otp: "",
    },

    // onSubmit: (values) => withdrawalMutation.mutate(payLoad),
    onSubmit: (values) => mutationVerifyOTP.mutate(values.value.otp),
  });

  const withdrawalMutation = useWithdrawCampaignDonation(
    (error) => onClose(false, error.message),
    (data) => onClose(true),
  );
  const mutationVerifyOTP = useVerifyOTP(
    (error: Error) => toast.error(error.message),
    (data: any) => withdrawalMutation.mutate(payLoad),
  );

  const mutateOTPRequest = useSendOTP(
    (error: Error) => toast.error(error.message),
    () => {},
  );

  const handleClose = (isCompleted: boolean, error?: string) => {
    form.reset();
    mutationVerifyOTP.reset();
    mutateOTPRequest.reset();
    onClose(isCompleted, error);
  };

  return (
    <KroModal
      maxWidth={500}
      className="p-0"
      closeOnOutsideClick={false}
      showCloseButton={true}
      isOpen={isOpen}
      onClose={() => handleClose(false)}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        className="m-2 flex flex-col items-center p-6 sm:m-3"
      >
        <img src="/assets/icons/mail.svg" alt="mail icon" className="mb-8" />
        <h2 className="mb-2 font-['Inter'] text-[32px] font-medium leading-[38.4px] tracking-[-0.005em]">
          Authenticate Withdrawal
        </h2>

        <p className="text-sm mb-1 text-center font-['Inter'] text-[14px] font-[400] leading-[22px] tracking-[-0.005em] text-neutral-500">
          We’ve sent a 6-character code to your email{" "}
          <strong className="font-medium text-neutral-900">{email}</strong>. The
          code expires shortly, so please enter it soon.
        </p>

        <div className="mb-8 flex flex-col gap-1">
          <div className="flex h-[38px] items-center justify-center text-[#C56A09]">
            <OTPResendCountDown
              minutes={KroConstants.OTP_REQUEST_TIMEOUT}
              onResend={() =>
                mutateOTPRequest.mutateAsync({
                  use_case: type,
                  email: email,
                })
              }
            />
          </div>
        </div>

        <form.Field
          validators={{
            onChange: ({ value }) => {
              return Validation.minLength(value, KroConstants.OTP_LENGTH);
            },
          }}
          name="otp"
          children={(field) => {
            return (
              <KroOTPBox
                errorMessage={
                  mutationVerifyOTP.error?.message ??
                  withdrawalMutation.error?.message
                }
                length={KroConstants.OTP_LENGTH}
                value={field.state.value}
                isInvalid={field.state.meta.errors.join(", ").trim() !== ""}
                onChange={(value) => field.handleChange(value)}
              ></KroOTPBox>
            );
          }}
        ></form.Field>

        <KroButton
          className="mt-8 w-full"
          title="Withdraw"
          isLoading={
            mutationVerifyOTP.isPending || withdrawalMutation.isPending
          }
          onclick={form.handleSubmit}
        ></KroButton>
      </form>
    </KroModal>
  );
};

export default WithdrawalAuthenticationModal;
