interface SayThanksResponse {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface SayThanksResponseMapper {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export function mapSayThanksResponse(
  data: SayThanksResponse,
): SayThanksResponseMapper {
  return {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
  };
}

console.log();
