import { useForm } from "@tanstack/react-form";
import Validation from "common/utils/validation";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import { KroUploadCardType } from "features/campaign/domain/enums/kroUploadCardType.enum";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { useUploadFilesAndSaveVideoLink } from "features/campaign/presentation/hooks/useUploadFilesAndSaveVideoLink";
import { KroButton } from "features/components/buttons/kroButton";
import KroUploadCard from "features/components/cards/kroUploadCard";
import KroTextField from "features/components/inputs/kroTextField";
import KroTextBetweenLines from "features/components/kroTextBetweenLines";
import { TabController } from "features/components/tab/kroTabBar";
import { FC } from "react";
import ReactPlayer from "react-player";
import { queryClient } from "index";
import useFormHandler from "../../hooks/useFormHandler";
import { toast } from "react-toastify";

interface EditPhotoVideoForm {
  youtubeLink: string;
  cover: File | string | null;
  image2: File | string | null;
  image3: File | string | null;
  image4: File | string | null;
  image5: File | string | null;
}

const PhotoVideosTab: FC<{
  campaign: CampaignModelMapper | undefined;
  tabController: TabController;
}> = ({ campaign, tabController }) => {
  // const { showSnackbar } = useSnackbar();
  const hasVideo = campaign?.videoUrl !== null;
  console.log(campaign?.imageUrls);
  const form = useForm<EditPhotoVideoForm>({
    defaultValues: {
      youtubeLink: campaign?.videoUrl?.replace(/^https?:\/\//, "") ?? "",
      cover: hasVideo ? null : (campaign.imageUrls?.[0] ?? null),
      image2: campaign?.imageUrls?.[hasVideo ? 0 : 1] ?? null,
      image3: campaign?.imageUrls?.[hasVideo ? 1 : 2] ?? null,
      image4: campaign?.imageUrls?.[hasVideo ? 2 : 3] ?? null,
      image5: campaign?.imageUrls?.[hasVideo ? 3 : 4] ?? null,
    },
    onSubmit(props) {
      if (campaign?.status === "ended") {
        toast.warning("Campaign cannot be updated as it has ended");
        form.reset();
        return;
      }
      const { youtubeLink, cover, image2, image3, image4, image5 } =
        props.value;
      const files = [cover, image2, image3, image4, image5].filter(
        (f) => f !== null && typeof f !== "string" && f instanceof File,
      );
      const payload = {
        link: youtubeLink ? `https://${youtubeLink}` : "",
        id: campaign?.id ?? "",
      };
      updateMediaMutation.mutate({ files: files as File[], payload });
    },
  });
  const updateMediaMutation = useUploadFilesAndSaveVideoLink(
    (error: Error) => {
      toast.error(error.message);
    },
    (_, __) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchCampaign", campaign?.id],
      });
      toast.success("Media updated successfully");
    },
  );

  useFormHandler(form, tabController);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      className="mt-9 max-w-[550px] justify-center"
    >
      <form.Subscribe
        //TODO:implement selector or use useStore
        // selector={(state) => state.values}
        children={(state) => {
          const youtubeLink = state.values.youtubeLink;

          return (
            <form.Field
              name="cover"
              children={(field) => {
                return youtubeLink && state.isFieldsValid ? (
                  <div className="mb-6 flex items-center justify-center overflow-hidden rounded-lg border bg-black">
                    <ReactPlayer
                      url={youtubeLink}
                      controls={true}
                      onError={(e) => {
                        toast.error(e.message);
                      }}
                      className="rounded-full"
                      width="100%"
                    />
                  </div>
                ) : (
                  <KroUploadCard
                    allowVideo={false}
                    id={field.name}
                    className="mb-6"
                    name={field.name}
                    value={field.state.value}
                    type={KroUploadCardType.LARGE}
                    onFileSelect={(file) => {
                      field.handleChange(file);
                    }}
                  />
                );
              }}
            />
          );
        }}
      ></form.Subscribe>

      <KroTextBetweenLines title="OR" />
      <form.Field
        name="youtubeLink"
        validators={{
          onChange: ({ value }) => {
            if (!value) return undefined;
            return Validation.youtubeLink(value);
          },
        }}
        children={(field) => (
          <KroTextField
            className="mb-10"
            value={field.state.value}
            name={field.name}
            errorMessage={field.state.meta.errors.join(", ")}
            id={field.name}
            onChange={(e) => {
              return field.handleChange(e.target.value);
            }}
            label="Add a Youtube link"
            placeHolder="Youtube link"
            type={KroInputFieldType.TEXTWITHADDONS}
          />
        )}
      />

      <label className="text-md font-medium-regular mb-2 block">
        Additional images
      </label>
      <div className="grid grid-cols-2 grid-rows-1 gap-[8.47px] xs:grid-cols-3 sm:grid-cols-4">
        <form.Field
          name="image2"
          children={(field) => (
            <KroUploadCard
              id={field.name}
              name={field.name}
              value={field.state.value}
              type={KroUploadCardType.SMALL}
              onFileSelect={(filePath) => {
                field.handleChange(filePath);
              }}
            />
          )}
        />
        <form.Field
          name="image3"
          children={(field) => (
            <KroUploadCard
              id={field.name}
              name={field.name}
              value={field.state.value}
              type={KroUploadCardType.SMALL}
              onFileSelect={(filePath) => {
                field.handleChange(filePath);
              }}
            />
          )}
        />
        <form.Field
          name="image4"
          children={(field) => (
            <KroUploadCard
              id={field.name}
              name={field.name}
              value={field.state.value}
              type={KroUploadCardType.SMALL}
              onFileSelect={(filePath) => {
                field.handleChange(filePath);
              }}
            />
          )}
        />
        <form.Field
          name="image5"
          children={(field) => (
            <KroUploadCard
              id={field.name}
              name={field.name}
              value={field.state.value}
              type={KroUploadCardType.SMALL}
              onFileSelect={(filePath) => {
                field.handleChange(filePath);
              }}
            />
          )}
        />
      </div>
      <div className="mt-[84px] flex gap-4">
        <button
          type="reset"
          onClick={() => form.reset()}
          className="max-w-[120px] rounded-[32px] border border-neutral-200 bg-neutral-50 px-[29px] py-[14px] text-center font-inter text-[16px] font-medium leading-[24px] tracking-[-0.005em] text-[#0F0F0F] hover:bg-neutral-100"
        >
          Discard
        </button>
        <KroButton.Base
          type="submit"
          isLoading={updateMediaMutation.isPending}
          hasIcon={updateMediaMutation.isPending}
          className="px-[29px] py-[14px]"
          isDisabled={updateMediaMutation.isPending}
          title="Save Changes"
        />
      </div>
    </form>
  );
};

export default PhotoVideosTab;
