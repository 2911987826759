import { KroButton } from "features/components/buttons/kroButton";
import { SocialMediaHolder } from "../../../../components/modals/socialMediaHolder";
import { campaignDonateForm } from "../../forms/campaignDonateForm";
import { useCampaignDonateForm } from "../../context/campaignDonateFormProvider";

import { FC, useState } from "react";
import KroRadioButton from "features/components/inputs/kroRadioButton";
import { useCreateComment } from "../../hooks/useCreateComment";
import { CommentPayload } from "features/campaign/domain/models/commentPayload";
import { queryClient } from "index";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { DonationResponseMapperWithAnon } from "features/campaign/domain/models/donationResponse";
import KroShareModal from "features/components/modals/kroShareModal";
import KroConstants from "common/constants/kroConstants";
import { getSessionUser } from "common/store/useAuthStore";
import { toast } from "react-toastify";

interface DonateNowProps {
  form: campaignDonateForm;
  isLoading: boolean;
  campaignId: string;
  data: CampaignModelMapper;
}

const DonateNow: FC<DonateNowProps> = ({ isLoading, form, data }) => {
  const [_isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const { setFormData } = useCampaignDonateForm();

  const handleIsAnonymousDonationToggle = (checked: boolean) => {
    setIsAnonymous(checked);
    setFormData((prevData) => ({
      ...prevData,
      donateAnonymously: checked,
    }));
  };

  const handleDonate = () => {
    form.handleSubmit();
  };

  return (
    <div className="flex w-full flex-col">
      <div className="items-justify mb-4 flex">
        <form.Field
          name="donateAnonymously"
          children={(field) => {
            return (
              <KroRadioButton
                id={field.name}
                label="Don’t display my name publicly on the fundraiser"
                name={field.name}
                isChecked={_isAnonymous}
                value="donateAnonymously"
                onToggle={(value) => {
                  field.handleChange(value);
                  return handleIsAnonymousDonationToggle(value);
                }}
                useSquareCheckbox={false}
              />
            );
          }}
        />
      </div>
      {/* <div className="items-justify mb-4 flex">
        <KroRadioButton
          id=""
          label="Yes, sign me up to hear updates from Kro Giving about how to change
          people’s lives. Unsubscribe anytime."
          name=""
          value=""
          useSquareCheckbox={false}
        />
      </div> */}
      <KroButton
        shadow=""
        title={"Donate Now"}
        onclick={handleDonate}
        // isDisabled={!isEmailVerified}
        isLoading={isLoading}
      />
    </div>
  );
};

const ThankYouForDonating = ({
  shareLink,
  campaignId,
  donationInfor,
}: {
  shareLink: string;
  campaignId: string;
  donationInfor: DonationResponseMapperWithAnon;
}) => {
  const [isShareCampaign, setIsShareCampaign] = useState(false);
  const user = getSessionUser();
  const handleIsShareCampaign = () => {
    setIsShareCampaign(true);
  };
  const handleCloseShareCampaign = () => {
    setIsShareCampaign(false);
  };
  const [message, setMessage] = useState("");

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };
  // const { showSnackbar } = useSnackbar();

  const mutation = useCreateComment(
    (error) => {
      toast.error(error.message);
    },

    (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchComments"],
      });

      setIsShareCampaign(true);
    },
  );

  const makeComment = () => {
    const payload: CommentPayload = {
      campaignId: campaignId,
      full_name: `${donationInfor.firstName} ${donationInfor.lastName}`,
      isAnonymous: donationInfor.isAnonymous,
      text: message,
      user_id: user?.id ?? "",
    };
    mutation.mutate(payload);
  };

  const socialMediaList = [
    { imgsrc: "/assets/icons/facebook.svg", medium: "Facebook" },
    { imgsrc: "/assets/icons/twitter-x.svg", medium: "X (Twitter)" },
    { imgsrc: "/assets/icons/whatsapp.svg", medium: "Whatsapp" },
    { imgsrc: "/assets/icons/qr-code-line.svg", medium: "QR Code" },
  ];

  const handleShare = (medium: string) => {
    let url = "";
    const message = KroConstants.FUNDRAISER_SUPPORT_MESSAGE + "\n";

    switch (medium) {
      case "Facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(message + " " + shareLink)}`;
        break;
      case "X (Twitter)":
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message + " " + shareLink)}`;
        break;
      case "Whatsapp":
        url = `https://wa.me/?text=${encodeURIComponent(message + " " + shareLink)}`;
        break;
      case "QR Code":
        break;
      default:
        break;
    }

    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <KroShareModal
        isOpen={isShareCampaign}
        onClose={handleCloseShareCampaign}
        campaignId={campaignId}
      />

      <div className="mx-[10px] mb-[10px] flex flex-col items-center justify-center">
        <div className="mt-9">
          <div className="mb-[6px] flex flex-col items-center justify-center">
            <img
              src="/assets/icons/praying_hand.svg"
              alt="giving"
              className="mb-1"
            />
            <h3 className="text-left font-inter text-[24px] font-[500] leading-[28.8px] tracking-[-0.005em]">
              Thank you
            </h3>
          </div>

          <h4 className="text-center font-inter text-[14px] font-[400] leading-[20px] tracking-[-0.006em] text-neutral-700">
            Your contribution has been received!
          </h4>
        </div>

        {!isShareCampaign ? (
          <>
            <div
              onFocus={(e) =>
                e.currentTarget.classList.add("ring-black", "border-black")
              }
              onBlur={(e) =>
                e.currentTarget.classList.remove(
                  "ring-black",
                  "border-neutral-900",
                )
              }
              className="mb-3 mt-5 flex w-full animate-fadeIn flex-row items-end overflow-hidden rounded-[20px] border border-[#E5E5E5] p-3"
            >
              <textarea
                className="w-full resize-none rounded-lg border-none pr-[6px] text-left font-inter text-[14px] font-[400] leading-[22px] tracking-[-0.005em] placeholder:text-[#A3A3A3] focus:outline-none"
                rows={4}
                placeholder="Send a word of encouragement..."
                onChange={handleMessageChange}
              />
              <button
                disabled={!message.trim()}
                onClick={makeComment}
                className={`m rounded-[32px] bg-neutral-900 px-3 py-[6px] text-left font-inter text-[14px] font-[500] leading-[20px] tracking-[-0.006em] text-white disabled:bg-[#E5E5E5] ${mutation.isPending ? "cursor-not-allowed opacity-50" : ""}`}
              >
                {mutation.isPending ? (
                  <div className="h-5 w-5 animate-spin rounded-full border-4 border-white border-t-transparent"></div>
                ) : (
                  "Send"
                )}
              </button>
            </div>

            <button
              className="flex w-full items-center justify-center gap-2 rounded-[32px] border border-neutral-200 bg-[#022928] py-[12px] font-inter text-body-lg font-medium leading-[24px] tracking-[-0.005em] text-white hover:bg-[#034d4a]"
              onClick={handleIsShareCampaign}
            >
              Share Campaign
              <img src="/assets/icons/share_Icon_white.svg" alt="Share" />
            </button>
          </>
        ) : (
          <>
            <div className="my-[6px] flex animate-fadeIn gap-1 rounded-md bg-state-success-lighter p-1">
              <img src="/assets/icons/green_check.svg" alt="" />
              <p className="bg-sta mr-1 text-left font-inter text-[12px] font-[500] leading-[16px] text-state-success-base">
                Comment shared!
              </p>
            </div>
            <div className="w-full rounded-[20px] bg-neutral-100 px-8 py-5">
              <p className="mb-4 text-center font-inter text-[14px] font-[400] leading-[20px] tracking-[-0.006em] text-neutral-700">
                Sharing your donation will add more success...
              </p>
              <div className="flex w-full justify-between">
                {socialMediaList.map((socialMedium, index) => (
                  <SocialMediaHolder
                    key={index}
                    imgSrc={socialMedium.imgsrc}
                    medium={socialMedium.medium}
                    onClick={() => handleShare(socialMedium.medium)}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { ThankYouForDonating };

const DonationFailed = ({
  retryPayment,
  onDone,
}: {
  retryPayment: () => void;
  onDone: () => void;
}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src="/assets/icons/donation_failed.svg" alt="donation failed" />
      <span className="text-H_8 font-[500]">Donation failed!</span>
      <span className="mt-1 text-body-md font-[400] text-neutral-700">
        Your donation was not successful.
      </span>
      <div className="mb-10 mt-10 flex space-x-4">
        <button
          onClick={retryPayment}
          className="rounded-full bg-black px-20 py-4 font-medium text-white"
        >
          Retry Payment
        </button>
        <button
          onClick={onDone}
          className="rounded-full border border-neutral-300 bg-white px-20 py-4 font-medium text-neutral-700"
        >
          Done
        </button>
      </div>
    </div>
  );
};
export { DonationFailed };

export default DonateNow;
