import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import KroTextField from "features/components/inputs/kroTextField";
import { campaignDonateForm } from "../../forms/campaignDonateForm";
import { FC, useState } from "react";
import Validation from "common/utils/validation";
import { KroButton } from "features/components/buttons/kroButton";
import KroLoader from "features/components/loader/kroLoader";
import { useSendOTP } from "features/onboarding/presentation/hooks/useOTP";
import EmailVerificationModal from "features/onboarding/presentation/components/create_account/EmailVerificationModal";
import { EOTPUseCases } from "features/onboarding/domain/enum/otpUseCase";
import { toast } from "react-toastify";

interface FundRaiserDetailsForPaymentProps {
  form: campaignDonateForm;
}
const FundRaiserDetailsForPayment: FC<FundRaiserDetailsForPaymentProps> = ({
  form,
}) => {
  return (
    <div className="mt-5 border-t border-stroke-soft-200">
      <h2 className="mt-5 text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] text-neutral-900">
        Your details
      </h2>
      <FormComponent form={form} />
    </div>
  );
};

const FormComponent: FC<FundRaiserDetailsForPaymentProps> = ({ form }) => {
  const isEmailVerified = form.useStore(
    (state) => state.values.isEmailVerified,
  );
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const handleShowVerificationModal = () => {
    setShowVerificationModal(true);
  };
  // const { showSnackbar } = useSnackbar();

  const handleCloseVerificationModal = (isVerified: boolean) => {
    if (isVerified) form.setFieldValue("isEmailVerified", isVerified);
    setShowVerificationModal(false);
  };

  const mutateOTPRequest = useSendOTP(
    (error: Error) => toast.error(error.message),
    () => handleShowVerificationModal(),
  );

  return (
    <div>
      <EmailVerificationModal
        type={EOTPUseCases.SIGN_IN}
        email={form.state.values.email}
        onClose={handleCloseVerificationModal}
        isOpen={showVerificationModal}
      />

      <div className="mt-3">
        <div className="flex flex-col gap-1 sm:flex-row">
          <div className="flex-1">
            <form.Field
              name="firstName"
              validators={{
                onChange: ({ value }) => {
                  const isEmpty = Validation.required(value);
                  if (isEmpty !== undefined) return isEmpty;
                  return Validation.oneWord(value);
                },
              }}
              children={(field) => {
                return (
                  <KroTextField
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    errorMessage={field.state.meta.errors.join(", ")}
                    onChange={(e) => field.handleChange(e.target.value)}
                    labelClassName="text-body-sm text-neutral-700 mb-2"
                    label="First Name"
                    type={KroInputFieldType.TEXT}
                    placeHolder="First Name"
                  />
                );
              }}
            />
          </div>
          <div className="flex-1">
            <form.Field
              name="lastName"
              validators={{
                onChange: ({ value }) => {
                  const isEmpty = Validation.required(value);
                  if (isEmpty !== undefined) return isEmpty;
                  return Validation.oneWord(value);
                },
              }}
              children={(field) => {
                return (
                  <KroTextField
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    errorMessage={field.state.meta.errors.join(", ")}
                    onChange={(e) => field.handleChange(e.target.value)}
                    labelClassName="text-body-sm text-neutral-700 mb-2"
                    label="Last Name"
                    type={KroInputFieldType.TEXT}
                    placeHolder="Last Name"
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
      <form.Field
        name="email"
        validators={{
          onChange: ({ value }) => Validation.required(value),
        }}
        children={(field) => {
          return (
            <KroTextField
              margin="mb-0"
              label={"Email Address"}
              type={KroInputFieldType.TEXTADDONS}
              placeHolder={"e.g. john.doe@gmail.com"}
              id={field.name}
              name={field.name}
              fillColor="bg-neutral-50"
              errorMessage={field.state.meta.errors.join(", ")}
              value={field.state.value}
              onChange={(e) => {
                if (isEmailVerified) {
                  form.setFieldValue("isEmailVerified", false);
                }
                return field.handleChange(e.target.value);
              }}
              addon={
                isEmailVerified ? (
                  <img src="/assets/icons/radio_checked.svg" alt="checked" />
                ) : mutateOTPRequest.isPending ? (
                  <KroLoader />
                ) : (
                  <KroButton.Base
                    title={"Verify"}
                    type="button"
                    isLoading={mutateOTPRequest.isPending}
                    className="px-3 py-[6px]"
                    hasIcon={false}
                    isDisabled={
                      field.state.meta.errors.length > 0 ||
                      field.name.length < 1
                    }
                    onclick={() =>
                      mutateOTPRequest.mutate({
                        use_case: EOTPUseCases.DONATION,
                        email: field.state.value,
                      })
                    }
                  />
                )
              }
            />
          );
        }}
      />
    </div>
  );
};

export default FundRaiserDetailsForPayment;
