interface ShareFavoriteButtonsProps {
  onShareClick: () => void;
  onFavoriteClick: () => void;
}

const ShareFavoriteButtons: React.FC<ShareFavoriteButtonsProps> = ({
  onShareClick,
  onFavoriteClick,
}) => {
  return (
    <div className="mt-5 flex items-center justify-between gap-[12px]">
      <button
        className="font-inter flex w-full items-center justify-center gap-1 rounded-[32px] border border-neutral-200 py-[12px] text-body-lg font-medium leading-[24px] tracking-[-0.006em] text-[#0F0F0F]"
        onClick={onShareClick}
      >
        <img src="/assets/icons/share_large.svg" alt="Share" />
        Share
      </button>
      {/* <button
        className="flex items-center justify-center border border-neutral-200 w-full py-[12px] gap-1 rounded-[32px] font-inter text-body-lg font-medium leading-[24px] tracking-[-0.006em] text-[#0F0F0F]"
        onClick={onFavoriteClick}
      >
        Favorite
        <img
          src="/assets/icons/favorite_large.svg"
          alt="Favorite"
          height="21px"
          width="18px"
        />
      </button> */}
    </div>
  );
};

export default ShareFavoriteButtons;
