import KroAmountInputField from "features/components/inputs/KroAmountInput";
import React, { useEffect, useState } from "react";
import PresetAmounts from "./campaignDonatePresetAmount";
import PaymentMethods from "./paymentMethods";
import PaymentOverview from "./paymentOverview";
import DonateNow from "./donateNow";
import TermsAndPrivacyPolicy from "./termsAndPrivacyPolicy";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { useInitializeDonation } from "../../hooks/useInitializeDonation";
import { DonationResponseMapperWithAnon } from "features/campaign/domain/models/donationResponse";
import { useForm } from "@tanstack/react-form";

import FundRaiserDetailsForPayment from "./fundRaiserDetails";
import { useCampaignDonateForm } from "../../context/campaignDonateFormProvider";
import Validation from "common/utils/validation";
import {
  useFetchDonationFees,
  useKroDonationFeeCalculation,
} from "../../hooks/useFetchDonationFees";
import { InitializeDonationPayload } from "features/campaign/domain/models/initializeDonationPayload";
import { scrollToFieldError } from "common/utils/scrollTo";
import { toast } from "react-toastify";
interface KroDonateProps {
  campaignId: string;
  campaignModel: CampaignModelMapper;
  onClose: (data?: DonationResponseMapperWithAnon) => void;
}

const CampaignDonate: React.FC<KroDonateProps> = ({
  campaignModel,
  campaignId,
  onClose,
}) => {
  const [amount, setAmount] = useState<string>("");

  const handlePresetSelected = (presetAmount: number) => {
    setAmount(presetAmount.toFixed(2));
  };

  const { data } = useFetchDonationFees();

  const { serviceFee, handleDonationAmountChange } =
    useKroDonationFeeCalculation(data);

  useEffect(() => {
    handleDonationAmountChange(parseFloat(amount));
  }, [amount, handleDonationAmountChange]);

  const total = parseFloat(amount);

  const { formData, setFormData } = useCampaignDonateForm();

  const form = useForm({
    defaultValues: {
      amount: formData.amount ?? "",
      firstName: formData.firstName ?? "",
      isEmailVerified: formData.isEmailVerified ?? false,
      lastName: formData.lastName ?? "",
      email: formData.email ?? "",
      reference: formData.reference ?? "",
      paymentMethod: formData.paymentMethod ?? "",
      donateAnonymously: false,
    },

    onSubmitInvalid: (props) => {},

    onSubmit: (values) => {
      if (!values.value.isEmailVerified) {
        toast.info("Please verify your email address to proceed");
        return;
      }

      const payload: InitializeDonationPayload = {
        amount: total,
        firstName: values.value.firstName,
        lastName: values.value.lastName,
        email: values.value.email,
        is_anonymous: values.value.donateAnonymously,
        id: campaignModel.id ?? "",
        fees: serviceFee,
      };

      setFormData((prevData) => ({
        email: values.value.email,
        isEmailVerified: values.value.isEmailVerified,
        firstName: values.value.firstName,
        lastName: values.value.lastName,
      }));

      mutation.mutate(payload);
    },
  });

  const mutation = useInitializeDonation(
    (error) => {
      toast.error(error.message);
    },
    (data: DonationResponseMapperWithAnon, variables: any) => {
      onClose(data);
    },
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="px-4 md:px-6">
        <div className="mb-[18px] mt-[26px] flex items-center justify-between gap-[14px]">
          <h2 className="text-left font-inter text-[20px] font-medium leading-[20px] tracking-[-0.006em] text-neutral-900">
            Donate
          </h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => onClose()}
          >
            <img src="/assets/icons/close.svg" alt="close" />
          </button>
        </div>

        <form.Field
          name="amount"
          validators={{
            onChange: ({ value }) => Validation.required(value),
          }}
          children={(field) => {
            scrollToFieldError(field);
            return (
              <>
                <KroAmountInputField
                  id={field.name}
                  name={field.name}
                  errorMessage={field.state.meta.errors.join(", ")}
                  title={"Enter Amount"}
                  currency={"₦"}
                  className="border border-gray-300 bg-[#FAFAFA] px-3"
                  value={amount}
                  placeHolder={"Enter amount"}
                  onChange={(e) => {
                    setAmount(e);
                    field.handleChange(e);
                  }}
                />
                {field.state.meta.errors ? (
                  <em role="alert">{field.state.meta.errors.join(", ")}</em>
                ) : null}
                {campaignModel.targetAmount !== undefined &&
                  (campaignModel.targetAmount ?? 0) > 0 && (
                    <PresetAmounts
                      targetAmount={campaignModel.targetAmount ?? 0}
                      onPresetSelected={(amount) => {
                        handlePresetSelected(amount);
                        field.handleChange(amount.toString());
                      }}
                    />
                  )}
              </>
            );
          }}
        />

        <PaymentMethods form={form} />
        <FundRaiserDetailsForPayment form={form} />
        <PaymentOverview
          donationFee={serviceFee}
          donatedAmount={parseInt(amount)}
        />
        <DonateNow
          campaignId={campaignId}
          form={form}
          data={campaignModel}
          isLoading={mutation.isPending}
        />
        <TermsAndPrivacyPolicy />
      </div>
    </form>
  );
};

export default CampaignDonate;
