import KroProgressBar, {
  variant,
} from "features/components/progress/kroProgressBar";
import KroBackButton from "features/components/buttons/kroBackButton";
import {
  KroCategoryBadge,
  // KroVerifiedBadge,
} from "features/components/display/kroBadges";
import KroText from "features/components/display/kroText";
import { KroButton } from "features/components/buttons/kroButton";
import { formatCurrency } from "common/utils/currencyFormater";
import useKroCountdown from "features/components/countdown/kroCountdown";
import { useUpdateCampaignStatus } from "../hooks/useUpdateStatusCampaign";
import { CampaignStatus } from "features/campaign/domain/models/updateCampaignStatusPayload";
import { useCreateCampaignForm } from "../context/CreateCampaignFormProvider";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { useNavigate } from "react-router-dom";
import Routes from "router/routes";
import { useState } from "react";
import ReactPlayer from "react-player";
import { getSessionUser } from "common/store/useAuthStore";
import useDiceBear from "common/hooks/useDiceBear";
import { getCampaignDefaultImage } from "common/utils/getCampaignDefauiltImage";
import { toast } from "react-toastify";

interface PreviewCampaignModalPageProps {
  data: CampaignModelMapper;
  onClose: (isPublished?: boolean) => void;
}

interface Media {
  type: "image" | "video";
  src: string;
}

export function PreviewCampaignModalPage({
  onClose,
  data,
}: PreviewCampaignModalPageProps) {
  const { formData } = useCreateCampaignForm();
  const campaignId = formData.id;
  // const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const user = getSessionUser();
  const avatar = useDiceBear({ seed: user?.firstName ?? "Fundraiser" });

  const media: { type: "image" | "video"; src: string }[] = [
    ...(data.videoUrl ? [{ type: "video" as const, src: data.videoUrl }] : []),
    ...(data.imageUrls?.map((image) => ({
      type: "image" as const,
      src: image,
    })) || []),
  ];
  const publishMutation = useUpdateCampaignStatus(
    (error: Error) => {
      toast.error(error.message);
    },
    (data: boolean) => {
      onClose(true);
    },
  );

  const onHandlePublish = () => {
    const payload = {
      id: campaignId,
      status: CampaignStatus.PUBLISHED,
    };
    publishMutation.mutate(payload);
  };

  const countdown = useKroCountdown(data.endDate);

  return (
    <div className="p-[20px] md:p-[42px]">
      <header className="mb-[12px] md:mb-[42px]">
        <h2 className="mb-2 text-left text-[24px] font-medium leading-[28.8px] tracking-[-0.005em] text-neutral-900">
          Preview Fundraiser
        </h2>
        <p className="text-left text-body-md font-normal leading-[22px] tracking-[-0.005em] text-neutral-500">
          Here’s a peek of your future fundraiser.
        </p>
      </header>
      <div className="item mb-[42px] flex w-full flex-col justify-center gap-[24px] rounded-3xl border border-neutral-200 p-3 md:flex-row md:gap-[52.55px] md:p-6">
        <div className="flex w-full items-center justify-center md:block">
          <MediaPreview
            Medias={media}
            campaignCategory={data.category}
          ></MediaPreview>
        </div>
        <div className="w-full self-start">
          <div className="mb-[19.11px]">
            <h3 className="mb-[9.55px] text-left text-[31.85px] font-semibold leading-[31.85px] tracking-[-0.005em] text-[#000929]">
              {data?.title}
            </h3>

            <div className="flex gap-2">
              <KroCategoryBadge category={data?.category ?? ""} />
              {/* <KroVerifiedBadge /> */}
            </div>
          </div>

          <div className="w-full">
            <div className="mb-[19.11px]">
              <h3 className="mb-[6.37px] text-left text-[15.92px] font-semibold leading-[2</div>5.48px] tracking-[-0.005em] text-[#000929]">
                About this fundraiser
              </h3>
              <KroText text={data?.story ?? ""} minWords={50}></KroText>
            </div>
            <div className="lg mb-[9.55px] flex items-center justify-between rounded-[12.74px] border-[0.8px] p-[9.55px]">
              <div className="flex items-center">
                <img
                  src={avatar}
                  alt="Avatar"
                  className="mr-[11.15px] h-[38.21px] w-[38.21px] rounded-full"
                />
                <p className="decoration-skip-ink-none text-left font-inter text-[16px] font-normal leading-[24px] tracking-[-0.011em] text-neutral-700 underline decoration-solid underline-offset-2">
                  {user?.organisationName ??
                    `${user?.firstName ?? "You"} ${user?.lastName ?? ""}`}{" "}
                  started this fundraiser
                </p>

                {/* <div className="flex-col">
                  <div className="flex items-center justify-start">
                    <h4 className="text-left font-inter text-[12.74px] font-medium leading-[19.11px] tracking-[-0.011em] text-stone-950">
                      {data?.nameOfBeneficiary ?? "N/A"}
                    </h4>
                    <img
                      src="/assets/icons/verified-badge.svg"
                      alt="verified"
                      height="20.7px"
                      width="20.7px"
                    />
                  </div>
                  <p className="text-left font-inter text-[11.15px] font-normal leading-[15.92px] tracking-[-0.006em] text-sub-600">
                    {data?.beneficiary ?? "N/A"}
                  </p>
                </div> */}
              </div>

              {/* <div className="flex items-center justify-center rounded-[6.36px] border-[0.8px] px-[9.55px] py-[6.37px] font-inter text-[11.15px] font-medium leading-[15.92px] tracking-[-0.006em] text-sub-600">
                Fundraiser
              </div> */}
            </div>
            <div className="border-neutral-200p mb-[19.11px] rounded-[12.74px] border-[0.8px] px-[9.55px] py-[15.92px]">
              <div className="mb-[10px] flex flex-row items-center justify-between">
                <span className="text-[16px] font-semibold leading-[20px] tracking-[-0.006em] text-[#0E121B]">
                  <span>{formatCurrency(data?.amountRaised ?? 0, true)}</span>{" "}
                  <span className="font-normal text-neutral-400">
                    raised of
                  </span>{" "}
                  <span className="font-normal text-neutral-400">
                    {formatCurrency(data?.targetAmount ?? 0, true)}
                  </span>
                </span>
                <span className="text-left font-inter text-[11.15px] font-medium leading-[12.74px] text-[#09101D]">
                  {countdown}
                </span>
              </div>
              <KroProgressBar
                height="h-3"
                customStyle={"mb-[12.74px]"}
                amountRaised={data?.amountRaised.toString()}
                varient={variant.gradient}
                amountToBeRasied={data?.targetAmount?.toString() ?? ""}
              />
              <div className="flex flex-row justify-between">
                <div className="flex items-center">
                  {/* <div className="flex -space-x-1">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <GenerateAvatar key={index} index={index} />
                    ))}
                  </div> */}
                  <span className="ml-2 text-neutral-400">0 Donors</span>
                </div>
              </div>
            </div>
            <KroButton
              className="mb-[12.74px] w-full"
              title="Donate"
              animated={false}
            ></KroButton>
            <div className="flex">
              <button className="decoration-skip-ink-none flex w-full items-center justify-center gap-1 rounded-[27.63px] border border-neutral-200 bg-neutral-50 py-[10.23px] text-center font-inter text-[15px] font-medium leading-[24px] tracking-[-0.005em] text-[#0F0F0F]">
                Share
                <img src="/assets/icons/share-fill.svg" alt="Share" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-5 mt-[42px] flex w-full flex-col-reverse justify-stretch gap-[10px] md:mb-0 md:flex-row md:justify-between">
        <KroBackButton onclick={() => onClose(false)} />
        <div className="flex flex-col items-stretch gap-[34px] md:flex-row md:items-center">
          <KroBackButton
            title="Edit"
            icon={"/assets/icons/edit.svg"}
            classname="hidden md:block"
            onclick={() => {
              navigate(Routes.CREATE_CAMPAIGN_INFORMATION);
            }}
          />
          <KroButton
            isDisabled={publishMutation.isPending}
            isLoading={publishMutation.isPending}
            className="min-w-[168px]"
            title={"Publish"}
            onclick={onHandlePublish}
          />
        </div>
      </div>
    </div>
  );
}

const MediaPreview = ({
  Medias,
  campaignCategory,
}: {
  Medias: Media[];
  campaignCategory: string;
}) => {
  const [isSelected, setIsSelected] = useState<{
    index: number;
    data: Media;
  } | null>({ data: Medias[0], index: 0 });

  if (Medias.length === 0) {
    return (
      <div className="h-full max-h-[475.3px] w-full max-w-[517.49px] overflow-hidden rounded-[12px] border-neutral-200 bg-white">
        <img
          src={getCampaignDefaultImage(campaignCategory as string)}
          alt="Default Campaign"
          className="h-full w-full object-cover"
        />
      </div>
    );
  }
  return (
    <>
      <div className="bg-b mb-[7.61px] flex items-center justify-center md:mb-[19.9px]">
        {isSelected && isSelected.data && isSelected.data.type === "video" ? (
          <div className="h-[183.37px] w-[329px] overflow-hidden rounded-xl bg-black md:h-[382.15px] md:w-[517.49px]">
            <ReactPlayer
              url={isSelected.data.src}
              controls
              width="100%"
              height="100%"
              className="rounded-xl"
            />
          </div>
        ) : isSelected && isSelected.data ? (
          <img
            src={isSelected.data.src}
            alt="Main Header"
            className="h-full max-h-[183.37px] w-full min-w-[329px] rounded-xl bg-black object-contain md:max-h-[382.15px] md:min-w-[517.49px]"
          />
        ) : (
          <img
            src="/assets/images/placeHolder.svg"
            alt="Main Header"
            className="h-full max-h-[183.37px] w-full min-w-[329px] rounded-xl bg-white md:max-h-[382.15px] md:w-[517.49px] md:bg-transparent"
          />
        )}
      </div>
      <div className="flex gap-[6px] overflow-x-auto md:gap-[16.87px]">
        {Array.from({ length: Medias.length > 5 ? Medias.length : 5 }).map(
          (_, index) => {
            const mediaItem = Medias[index];
            const isSelectedMedia = isSelected?.index === index;

            return (
              <div
                key={index}
                className={`h-[41px] w-[60.74px] cursor-pointer rounded-[8px] md:h-[58.23px] md:w-[90px] md:rounded-[10px] ${
                  isSelectedMedia
                    ? "border border-[#2563EB] p-[1px] md:p-[2.01px]"
                    : ""
                }`}
                onClick={() => {
                  setIsSelected({ data: mediaItem ?? null, index: index });
                }}
              >
                {mediaItem?.type === "video" ? (
                  <div className="h-full w-full overflow-hidden rounded-[8px] md:rounded-[10px]">
                    <ReactPlayer
                      playing={false}
                      light={true}
                      url={mediaItem.src}
                      width="100%"
                      height="100%"
                      className="pointer-events-none"
                    />
                  </div>
                ) : (
                  <img
                    src={mediaItem?.src ?? "/assets/images/placeHolder.svg"}
                    alt={`${index + 1}`}
                    className="h-full w-full rounded-[8px] object-cover md:rounded-[10px]"
                  />
                )}
              </div>
            );
          },
        )}
      </div>
    </>
  );
};

// const GenerateAvatar: FC<{ index: number }> = ({ index }) => {
//   const seed = `Donor ${index + 1}`;
//   const avatar = useDiceBear({ seed });
//   return (
//     <img
//       key={index}
//       src={avatar}
//       alt={seed}
//       className="h-[15.92px] w-[15.92px] rounded-full border border-white"
//     />
//   );
// };
