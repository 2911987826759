import { KroButton } from "features/components/buttons/kroButton";
import KroModal from "features/components/modals/kroModal";
import React from "react";
import { useNavigate } from "react-router-dom";
import Routes from "router/routes";

interface WithdrawalSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WithdrawalSuccessModal: React.FC<WithdrawalSuccessModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigator = useNavigate();
  const handleNavigate = () => {
    navigator(Routes.MY_CAMPAIGNS);
    return;
  };

  return (
    <KroModal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={476}
      closeOnOutsideClick={false}
      showCloseButton={true}
      className="p-3"
    >
      <div className="inline-flex w-full flex-col items-center justify-stretch gap-8 p-6">
        <img src="/assets/icons/cash-payment 1.svg" alt="success" />

        <div className="flex flex-col items-center justify-center gap-2 self-stretch">
          <div className="text-center text-[32px] font-medium leading-[38.40px] text-[#0f0f0f]">
            Withdrawal Request Successful!
          </div>
          <div className="text-sm leading-snug h-11 text-center font-normal text-neutral-500">
            Your payout is being processed and the money will be on it's way
            shortly.
          </div>
        </div>
        <div className="mt-8 flex w-full flex-col items-stretch justify-stretch">
          <KroButton
            title={"Back to Home"}
            type="submit"
            classes="w-full"
            onclick={handleNavigate}
          />
        </div>
      </div>
    </KroModal>
  );
};

export default WithdrawalSuccessModal;
