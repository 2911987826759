import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { FC, useRef, useState } from "react";
import WithdrawalDetails from "./withdrawalDetails";
import KroModal from "features/components/modals/kroModal";
import WithdrawalConfirmation from "./withdrawalConfirmation";
import { getSessionUser } from "common/store/useAuthStore";
import WithdrawalAuthenticationModal from "./withdrawalAuthentication";
import { EOTPUseCases } from "features/onboarding/domain/enum/otpUseCase";
import WithdrawalSuccessModal from "./withdrawalSucessModal";
import WithdrawalErrorModal from "./withdrawalErrorModal";
import {
  WithdrawDonationModel,
  WithdrawDonationPayload,
} from "features/dashboard/domain/model/withdrawDonationPayload";
import { useSendOTP } from "features/onboarding/presentation/hooks/useOTP";
import { toast } from "react-toastify";

/**
 * WithdrawalModal component handles the display and logic for withdrawing funds from a campaign.
/**
 * WithdrawalModal component handles the withdrawal process for a campaign.
 * It manages multiple modals for different stages of the withdrawal process:
 * - Withdrawal Details
 * - Withdrawal Confirmation
 * - Withdrawal Authentication
 * - Withdrawal Error
 * - Withdrawal Success
 * 
 * * The component uses several state variables to manage the visibility of different modals:
 * - isWithdrawalConfirmModalOpen: Controls the visibility of the withdrawal confirmation modal.
 * - isWithdrawalAuthenticationModalOpen: Controls the visibility of the withdrawal authentication modal.
 * - isErrorModalOpen: Controls the visibility of the error modal.
 * - isSuccessModalOpen: Controls the visibility of the success modal.
 *
 * The component also uses refs to store the withdrawal amount and payload data.
 * The component handles the following actions:
 * - handleWithdrawalDetailsModalClose: Closes the withdrawal details modal and opens the confirmation modal if a withdrawal amount is provided.
 * - handleWithdrawalConfirmationModalClose: Closes the confirmation modal and triggers OTP mutation if a payload is provided.
 * - handleWithdrawalAuthenticationModalClose: Closes the authentication modal and opens the success or error modal based on the result.
 * - handleErrorModalOpen/Close: Opens and closes the error modal.
 * - handleSuccessModalOpen/Close: Opens and closes the success modal.

 * @param {Object} props - The component props.
 * @param {CampaignModelMapper} props.campaign - The campaign data.
 * @param {boolean} props.isModalOpen - Flag to indicate if the withdrawal details modal is open.
 * @param {Function} props.onModalClose - Function to handle closing the withdrawal details modal.
 *
 * @returns {JSX.Element} The rendered component.
 */
const WithdrawalModal: FC<{
  campaign: CampaignModelMapper;
  isModalOpen: boolean;
  onModalClose: () => void;
}> = ({ campaign, isModalOpen: isWithdrawalDetailsOpen, onModalClose }) => {
  // const { showSnackbar } = useSnackbar();
  const OTP_USE_CASE = EOTPUseCases.WITHDRAWAL;
  const sentOTMutation = useSendOTP(
    (error) => {
      toast.error(error.message);
    },
    (data) => {
      handleWithdrawalAuthenticationModalOpen();
    },
  );
  const user = getSessionUser();
  const withdrawalAmount = useRef<number | null>(null);
  const withdrawDonationPayload = useRef<WithdrawDonationModel | null>(null);

  const handleWithdrawalDetailsModalClose = (_withdrawalAmount?: number) => {
    onModalClose();
    if (!_withdrawalAmount) return;
    withdrawalAmount.current = _withdrawalAmount;
    handleWithdrawalConfirmationModalOpen();
  };

  const [isWithdrawalConfirmModalOpen, setWithdrawalConfirmModalOpen] =
    useState(false);
  const handleWithdrawalConfirmationModalClose = (
    payload?: WithdrawDonationPayload,
  ) => {
    setWithdrawalConfirmModalOpen(false);
    if (!payload) return;
    withdrawDonationPayload.current = {
      ...payload,
      campaign_id: campaign.id ?? "",
      is_verified: true,
    };
    sentOTMutation.mutate({
      email: user?.email ?? "",
      use_case: OTP_USE_CASE,
    });
  };

  const handleWithdrawalConfirmationModalOpen = () => {
    setWithdrawalConfirmModalOpen(true);
  };

  const [
    isWithdrawalAuthenticationModalOpen,
    setWithdrawalAuthenticationModalOpen,
  ] = useState(false);
  const handleWithdrawalAuthenticationModalClose = (
    isCompleted: boolean,
    error?: string,
  ) => {
    setWithdrawalAuthenticationModalOpen(false);

    if (error) {
      handleErrorModalOpen();
      return;
    }
    if (isCompleted) {
      handleSuccessModalOpen();
    }
  };
  const handleWithdrawalAuthenticationModalOpen = () => {
    if (!user?.email) {
      return;
    }
    setWithdrawalAuthenticationModalOpen(true);
  };

  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const handleErrorModalOpen = () => {
    setErrorModalOpen(true);
  };

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const handleSuccessModalOpen = () => {
    setSuccessModalOpen(true);
  };

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  return (
    <>
      <KroModal
        maxWidth={608}
        className="px-0"
        isOpen={isWithdrawalDetailsOpen}
        onClose={onModalClose}
      >
        <WithdrawalDetails
          onClose={handleWithdrawalDetailsModalClose}
          campaign={campaign}
        />
      </KroModal>
      <KroModal
        maxWidth={608}
        className="px-0"
        isOpen={isWithdrawalConfirmModalOpen}
        onClose={() => setWithdrawalConfirmModalOpen(false)}
      >
        <WithdrawalConfirmation
          withdrawalAmount={withdrawalAmount.current ?? 0}
          onClose={handleWithdrawalConfirmationModalClose}
          campaignId={campaign.id ?? ""}
        />
      </KroModal>

      <WithdrawalAuthenticationModal
        email={user?.email ?? ""}
        payLoad={withdrawDonationPayload.current!}
        isOpen={isWithdrawalAuthenticationModalOpen}
        type={OTP_USE_CASE}
        onClose={handleWithdrawalAuthenticationModalClose}
      />
      <WithdrawalErrorModal
        isOpen={isErrorModalOpen}
        onClose={handleErrorModalClose}
      />
      <WithdrawalSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleSuccessModalClose}
      />
    </>
  );
};

export default WithdrawalModal;
