import KroBackButton from "features/components/buttons/kroBackButton";
import { KroButton } from "features/components/buttons/kroButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFetchCampaign from "../../hooks/useFetchCampaign";
import { DonationResponseMapperWithAnon } from "features/campaign/domain/models/donationResponse";
import { useInitializeDonation } from "../../hooks/useInitializeDonation";
import { useForm } from "@tanstack/react-form";
import { InitializeDonationPayload } from "features/campaign/domain/models/initializeDonationPayload";
import { useCampaignDonateForm } from "../../context/campaignDonateFormProvider";
import KroRadioButton from "features/components/inputs/kroRadioButton";
import { useEffect, useRef, useState } from "react";
import Routes from "router/routes";
import { formatCurrency } from "common/utils/currencyFormater";
import {
  useFetchDonationFees,
  useKroDonationFeeCalculation,
} from "../../hooks/useFetchDonationFees";
import KroShareModal from "features/components/modals/kroShareModal";
import KroModal from "features/components/modals/kroModal";
import KroIframeMessageListener from "common/hooks/useKroIframeListener";
import { VerifyDonationPayload } from "features/campaign/domain/models/verifyDonationPayload";
import useVerifyDonation from "../../hooks/useVerifyDonation";
import { queryClient } from "index";
import KroLoadingIndicator, {
  LoaderType,
} from "features/components/loader/kroCircularIndicator";
import { toast } from "react-toastify";

const PaymentSummaryPage = () => {
  const { id: campaignId } = useParams<{ id: string }>();
  const { data: campaign } = useFetchCampaign(campaignId || "");
  const { formData, setFormData } = useCampaignDonateForm();
  const [_isAnonymous, setIsAnonymous] = useState<boolean>(false);

  const [isIframeOpen, setIsIframeOpen] = useState(false);

  const verifyDonation = (payload: VerifyDonationPayload) => {
    setIsIframeOpen(false);

    verifyDonationMutation.mutate(payload);
  };

  const verifyDonationMutation = useVerifyDonation(
    (error) => {
      navigate(Routes.DONATION_FAILED(campaignId ?? ""), location.state);
    },
    (data) => {
      navigate(Routes.DONATION_SUCCESSFUL(campaignId ?? ""), location.state);

      queryClient.invalidateQueries({
        queryKey: ["fetchCampaign", campaignId],
      });
    },
  );

  const [link, setLink] = useState("");
  const donorInformation = useRef<DonationResponseMapperWithAnon | null>(null);

  const handlePaystack = (data?: DonationResponseMapperWithAnon) => {
    setLink(data?.paymentLink ?? "");
    if (data != null) setIsIframeOpen(true);
    donorInformation.current = data ?? null;
  };

  const mutation = useInitializeDonation(
    (error) => {
      toast.error(error.message);
    },
    (data: DonationResponseMapperWithAnon, variables: any) => {
      handlePaystack(data);
    },
  );
  const handleIsAnonymousDonationToggle = (checked: boolean) => {
    setIsAnonymous(checked);
    setFormData((prevData) => ({
      ...prevData,
      donateAnonymously: checked,
    }));
  };

  const location = useLocation();

  useEffect(() => {
    if (!location.state?.amount) {
      navigate(Routes.ENTER_AMOUNT(campaignId ?? ""));
    } else {
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const { data, isLoading } = useFetchDonationFees();

  const { serviceFee, handleDonationAmountChange } =
    useKroDonationFeeCalculation(data);

  useEffect(() => {
    handleDonationAmountChange(parseFloat(location.state.amount ?? 0));
  }, [location.state.amount, handleDonationAmountChange]);

  const form = useForm({
    defaultValues: {
      amount: formData.amount ?? "",
      firstName: formData.firstName ?? "",
      isEmailVerified: formData.isEmailVerified ?? false,
      lastName: formData.lastName ?? "",
      email: formData.email ?? "",
      reference: formData.reference ?? "",
      paymentMethod: formData.paymentMethod ?? "",
      donateAnonymously: false,
    },

    onSubmitInvalid: (props) => {},

    onSubmit: (values) => {
      if (!values.value.isEmailVerified) {
        toast.info("Please verify your email address to proceed");
        return;
      }

      const payload: InitializeDonationPayload = {
        amount: parseFloat(location.state.amount),
        firstName: values.value.firstName,
        lastName: values.value.lastName,
        email: values.value.email,
        is_anonymous: values.value.donateAnonymously,
        id: campaign?.id ?? "",
        fees: serviceFee,
      };

      setFormData((prevData) => ({
        email: values.value.email,
        isEmailVerified: values.value.isEmailVerified,
        firstName: values.value.firstName,
        lastName: values.value.lastName,
        reference: values.value.reference,
      }));

      mutation.mutate(payload);
    },
  });

  const [showCampaignDetailsShareModal, setShowCmapaignDetailsShareModal] =
    useState<boolean>(false);

  const handleDonate = () => {
    form.handleSubmit();
  };

  const handleBackButton = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(Routes.ENTER_AMOUNT(campaignId ?? ""));
    }
  };

  const total = parseFloat(location.state.amount) + serviceFee;

  if (isLoading) {
    return <KroLoadingIndicator type={LoaderType.FIXED} />;
  }

  return (
    <>
      {/* <KroModal
        isOpen={isDonateFailedModalOpen}
        onClose={handleCloseDonationFailedModal}
        maxWidth={608}
        showCloseButton={true}
        className="px-10 pb-5"
      >
        <DonationFailed
          onDone={handleCloseDonationFailedModal}
          retryPayment={() => {
            handleCloseDonationFailedModal();
            navigate(Routes.ENTER_AMOUNT(campaignId ?? ""));
          }}
        />
      </KroModal> */}
      {/* <KroModal
        isOpen={isVerificationModalOpen}
        onClose={handleCloseVerificationModal}
        maxWidth={400}
        maxHeight={400}
        showCloseButton={true}
        className="px-10 pb-5"
      >
        <div>Verification...</div>
      </KroModal> */}
      {/* <KroModal
        lottieAnimation={true}
        isOpen={isDonateSuccessModalOpen}
        onClose={() => setIsDonateSuccessModalOpen(false)}
        maxWidth={510}
        showCloseButton={true}
        className="px-0 pb-0"
      >
        <ThankYouForDonating
          donationInfor={donorInformation.current!}
          campaignId={campaignId ?? ""}
          shareLink={window.location.origin + window.location.pathname}
        />
      </KroModal> */}
      {/* <OnDonateModal
        campaign={campaign}
        campaignId={campaignId ?? ""}
        isOpen={isDonateModalOpen}
        handlePaystackOpen={() => setIsDonateModalOpen(true)}
        onClose={handleCloseVerificationModal}
      /> */}
      <KroShareModal
        campaignId={campaignId ?? ""}
        isOpen={showCampaignDetailsShareModal}
        onClose={() => setShowCmapaignDetailsShareModal(false)}
      />
      <KroModal
        isOpen={isIframeOpen}
        onClose={() => setIsIframeOpen(false)}
        maxWidth={550}
        maxHeight={900}
        className="m-0 px-1 py-14 sm:py-0"
        showCloseButton={true}
      >
        <KroIframeMessageListener
          url={link}
          onSuccess={(data) => {
            const payload: VerifyDonationPayload = {
              reference: data?.reference ?? "",
              id: campaignId ?? "",
            };
            verifyDonation(payload);
          }}
        />
      </KroModal>

      <div className="flex min-h-[calc(100vh-280px)] flex-col">
        <div className="flex flex-grow items-center justify-center px-4">
          <div className="w-full max-w-[608px]">
            <div className="flex flex-col items-start rounded-3xl border border-neutral-200 p-6">
              <KroBackButton
                title="Payment summary"
                onclick={handleBackButton}
                classname="lg:flex hidden"
              />
              <p className="mb-4 flex text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] lg:hidden">
                Payment summary
              </p>

              <div className="mt-4 space-y-4 sm:mt-5">
                <h2 className="text-lg sm:text-xl font-medium text-neutral-900">
                  Overview
                </h2>

                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <span className="text-base sm:text-lg font-medium text-neutral-900">
                      Your donation
                    </span>
                    <span className="text-base sm:text-lg font-medium text-neutral-900">
                      {formatCurrency(
                        isNaN(location.state.amount)
                          ? "0"
                          : location.state.amount,
                        true,
                      )}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <span className="text-base sm:text-lg font-medium text-neutral-900">
                      3rd party bank charges
                    </span>
                    <span className="text-base sm:text-lg font-medium text-neutral-900">
                      ₦{formatCurrency(serviceFee.toFixed(2)) ?? "0"}
                    </span>
                  </div>

                  <div className="mt-3 border-t border-stroke-soft-200 pt-3">
                    <div className="flex items-center justify-between">
                      <span className="text-lg sm:text-xl font-semibold text-[#0F0F0F]">
                        Total
                      </span>
                      <span className="text-lg sm:text-xl font-semibold text-[#0F0F0F]">
                        {formatCurrency(isNaN(total) ? "0" : total, true)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <form.Field
                    name="donateAnonymously"
                    children={(field) => (
                      <KroRadioButton
                        id={field.name}
                        label="Make my donation anonymous"
                        name={field.name}
                        isChecked={_isAnonymous}
                        value="donateAnonymously"
                        onToggle={(value) => {
                          field.handleChange(value);
                          return handleIsAnonymousDonationToggle(value);
                        }}
                        useSquareCheckbox={false}
                      />
                    )}
                  />
                </div>

                <KroButton
                  isLoading={mutation.isPending}
                  onclick={handleDonate}
                  title="Donate now"
                  className="mt-4 w-full"
                />

                <div className="text-sm sm:text-base mt-6 text-center text-neutral-700">
                  <span>
                    By choosing the payment method above, you agree to the Giv{" "}
                    <button className="underline">Terms of Service</button> and
                    acknowledge the{" "}
                    <button className="underline">Privacy Notice</button>.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSummaryPage;
