const TermsAndPrivacyPolicy = () => {
  return (
    <div className="mb-10 mt-10">
      <span>
        <span className="text-body-lg font-[400] text-neutral-700">
          By choosing the payment method above, you agree to the Giv
        </span>{" "}
        <button className="text-body-lg font-[400] text-neutral-700 underline">
          Terms of Service
        </button>
        <span className="ml-1 mr-1 text-body-lg font-[400] text-neutral-700">
          and acknowledge the
        </span>
        <button className="text-body-lg font-[400] text-neutral-700 underline">
          Privacy Notice.
        </button>
      </span>
    </div>
  );
};

export default TermsAndPrivacyPolicy;
