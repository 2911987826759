import KroUploadCard from "features/components/cards/kroUploadCard";
import KroTextBetweenLines from "features/components/kroTextBetweenLines";
import KroTextField from "features/components/inputs/kroTextField";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import { KroUploadCardType } from "features/campaign/domain/enums/kroUploadCardType.enum";
import KroBackButton from "features/components/buttons/kroBackButton";
import { KroButton } from "features/components/buttons/kroButton";
import { useUploadFilesAndSaveVideoLink } from "../hooks/useUploadFilesAndSaveVideoLink";
import { useCreateCampaignForm } from "../context/CreateCampaignFormProvider";
import { useForm } from "@tanstack/react-form";
import Validation from "common/utils/validation";
import { SaveVideoPayload } from "features/campaign/domain/models/saveVideoPayload";
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "router/routes";
import CampaignCounterComponent from "../components/campaignCreate/campaignPageCounterComponent";
import ReactPlayer from "react-player";
import { useCampaignVideoUpload } from "../context/uploadVideoContext";
import { useEffect } from "react";
import { toast } from "react-toastify";

const CampaignImageUploadPage = () => {
  const { formData, setFormData, validateStepAccess } = useCreateCampaignForm();

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    validateStepAccess(location.pathname);
  }, [location, validateStepAccess]);

  const { isVideoUploading, onUploadVideo } = useCampaignVideoUpload();
  // const { showSnackbar } = useSnackbar();

  const form = useForm({
    defaultValues: {
      youtubeLink: formData.youtubeLink?.replace(/^https?:\/\//, "") ?? "",
      cover: formData.cover ?? null,
      image2: formData.image2 ?? null,
      image3: formData.image3 ?? null,
      image4: formData.image4 ?? null,
      image5: formData.image5 ?? null,
    },
    onSubmit: (values) => {
      const payload = {
        link: values.value.youtubeLink
          ? `https://${values.value.youtubeLink}`
          : "",
        id: formData.id ?? "",
      };

      const cover: File = values.value.cover;

      let files = [
        values.value.image2,
        values.value.image3,
        values.value.image4,
        values.value.image5,
      ];

      if (cover && cover.type && cover.type.startsWith("image/")) {
        files = [cover, ...files];
      } else if (!values.value.youtubeLink) {
        setFormData((prevData) => ({
          ...prevData,
          cover: cover,
        }));
        onUploadVideo(cover);
      }

      files = files.filter((e) => e !== null);

      if (!files.length && !values.value.youtubeLink) {
        navigate(Routes.CREATE_CAMPAIGN_TARGET);
        return;
      }

      mutation.mutate({ payload, files });
    },
  });

  const mutation = useUploadFilesAndSaveVideoLink(
    (error: Error) => {},
    (
      data: Boolean,
      variables: { payload: SaveVideoPayload; files: File[] },
    ) => {
      setFormData((prevData) => ({
        ...prevData,
        youtubeLink: variables.payload.link ?? "",
        image2: variables.files[0] ?? prevData.image2,
        image3: variables.files[1] ?? prevData.image3,
        image4: variables.files[2] ?? prevData.image4,
        image5: variables.files[3] ?? prevData.image5,
      }));
      navigate(Routes.CREATE_CAMPAIGN_TARGET);
    },
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="flex h-full w-full max-w-[606px] animate-fadeIn flex-col"
    >
      <div className="flex w-full flex-1 flex-col md:max-w-[500px]">
        <div className="md:my-auto">
          <CampaignCounterComponent
            pageCount={3}
            title={"Photo & Video"}
            onSkipClicked={() => navigate(Routes.CREATE_CAMPAIGN_TARGET)}
            showSkip={true}
          />
          <form.Subscribe
            //TODO:implement selector or use useStore
            // selector={(state) => state.values}
            children={(state) => {
              const youtubeLink = state.values.youtubeLink;

              return (
                <form.Field
                  name="cover"
                  children={(field) => {
                    return youtubeLink && state.isFieldsValid ? (
                      <div className="mb-6 flex items-center justify-center overflow-hidden rounded-lg border bg-black">
                        <ReactPlayer
                          url={youtubeLink}
                          controls={true}
                          onError={(e) => {
                            toast.error(e.message);
                          }}
                          className="rounded-full"
                          width="100%"
                        />
                      </div>
                    ) : (
                      <KroUploadCard
                        id={field.name}
                        className="mb-6"
                        name={field.name}
                        value={field.state.value}
                        type={KroUploadCardType.LARGE}
                        onFileSelect={(file) => {
                          field.handleChange(file);
                        }}
                      />
                    );
                  }}
                />
              );
            }}
          ></form.Subscribe>

          {!isVideoUploading && (
            <>
              <KroTextBetweenLines title="OR" />
              <form.Field
                name="youtubeLink"
                validators={{
                  onChange: ({ value }) => {
                    if (!value) return undefined;
                    return Validation.youtubeLink(value);
                  },
                }}
                children={(field) => (
                  <KroTextField
                    className="mb-10"
                    value={field.state.value}
                    name={field.name}
                    errorMessage={field.state.meta.errors.join(", ")}
                    id={field.name}
                    onChange={(e) => {
                      return field.handleChange(e.target.value);
                    }}
                    label="Add a Youtube link"
                    placeHolder="Youtube link"
                    type={KroInputFieldType.TEXTWITHADDONS}
                  />
                )}
              />
            </>
          )}

          <label className="text-md font-medium-regular mb-2 block">
            Additional images
          </label>
          <div className="grid grid-cols-2 grid-rows-1 gap-[8.47px] xs:grid-cols-3 sm:grid-cols-4">
            <form.Field
              name="image2"
              children={(field) => (
                <KroUploadCard
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  type={KroUploadCardType.SMALL}
                  onFileSelect={(filePath) => {
                    field.handleChange(filePath);
                  }}
                />
              )}
            />
            <form.Field
              name="image3"
              children={(field) => (
                <KroUploadCard
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  type={KroUploadCardType.SMALL}
                  onFileSelect={(filePath) => {
                    field.handleChange(filePath);
                  }}
                />
              )}
            />
            <form.Field
              name="image4"
              children={(field) => (
                <KroUploadCard
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  type={KroUploadCardType.SMALL}
                  onFileSelect={(filePath) => {
                    field.handleChange(filePath);
                  }}
                />
              )}
            />
            <form.Field
              name="image5"
              children={(field) => (
                <KroUploadCard
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  type={KroUploadCardType.SMALL}
                  onFileSelect={(filePath) => {
                    field.handleChange(filePath);
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex h-auto w-full flex-col-reverse justify-between gap-3 py-[51px] md:flex-row">
        <KroBackButton
          onclick={() => {
            if (window.history.length > 1) {
              navigate(-1);
            } else {
              navigate(Routes.CREATE_CAMPAIGN_INFORMATION);
            }
          }}
        />
        <KroButton
          type="submit"
          onclick={form.handleSubmit}
          isLoading={mutation.isPending}
          title={"Continue"}
        />
      </div>
    </form>
  );
};

export default CampaignImageUploadPage;
