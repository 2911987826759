import { formatCurrency } from "common/utils/currencyFormater";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { PaymentMethodsModel } from "features/campaign/domain/models/paymentMethodsModel";
import useFetchCampaign from "features/campaign/presentation/hooks/useFetchCampaign";
import { useKroFee } from "features/campaign/presentation/hooks/useKroFee";
import { KroButton } from "features/components/buttons/kroButton";
import KroAmountInputField from "features/components/inputs/KroAmountInput";
import KroLoadingIndicator, {
  LoaderType,
} from "features/components/loader/kroCircularIndicator";
import { FC } from "react";
import PaymentMethodsItem from "./withdrawalMethod";
import WithdrawalOverview from "./withdrawalOverview";
import { toast } from "react-toastify";

const PAYMENT_METHODS: PaymentMethodsModel[] = [
  {
    iconPath: "/assets/icons/bank-transfer.svg",
    name: "Bank Transfer",
  },
];

interface WithdrawDetailsProps {
  campaign: CampaignModelMapper;
  onClose?: (withdrawalAmount?: number) => void;
}

const WithdrawalDetails: FC<WithdrawDetailsProps> = ({ campaign, onClose }) => {
  let campaignModel = campaign;
  // const { showSnackbar } = useSnackbar();
  const { data, error: errorFetchingCampaign } = useFetchCampaign(
    campaign.id ?? "",
  );

  const {
    error: errorFetchingKroFee,
    isLoading: isLoadingKroFee,
    data: kroFee,
  } = useKroFee();

  if (data) campaignModel = data;
  if (errorFetchingCampaign || errorFetchingKroFee) {
    toast.error(
      errorFetchingCampaign?.message ?? errorFetchingKroFee?.message ?? "",
    );
    onClose?.();
  }

  const handleWithdrawal = () => {
    if (totalWithdrawalAmount <= 0) {
      toast.warning("Withdrawal amount must be greater than zero.");
      return;
    }
    return onClose?.(totalWithdrawalAmount);
  };

  const withdrawAmount = campaignModel.amountRaised;
  const withdrawalFee = kroFee?.percentage
    ? (withdrawAmount * kroFee.percentage) / 100
    : 0;
  const totalWithdrawalAmount = withdrawAmount - withdrawalFee;

  return (
    <div className="mx-5 lg:mx-10">
      <header className="mb-[18px] mt-[26px] w-full">
        <h2 className="text-left font-inter text-[20px] font-medium leading-[20px] tracking-[-0.006em]">
          Withdraw
        </h2>
      </header>
      {isLoadingKroFee ? (
        <div className="w-full p-6">
          {" "}
          <KroLoadingIndicator
            type={LoaderType.FIXED}
            heightPX={400}
            showLogo={false}
          />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-6 pb-5 pt-6">
          <KroAmountInputField
            title={"You’re withdrawing"}
            currency={"₦"}
            value={formatCurrency(withdrawAmount)}
            readOnly={true}
            className="rounded-[20px] border border-neutral-200 bg-neutral-50 px-3"
            placeHolder={""}
            onChange={() => {}}
          ></KroAmountInputField>

          <div className="h-1 border-b border-[#E1E4EA]"></div>

          <div className="flex flex-col gap-0">
            <h4 className="h-full max-h-[26px] text-left font-inter text-[18px] font-medium leading-[26px] tracking-[-0.005em]">
              Withdrawal Method
            </h4>
            {PAYMENT_METHODS.map((pMethod) => (
              <PaymentMethodsItem
                key={pMethod.name}
                iconPath={pMethod.iconPath}
                name={pMethod.name}
                isSelected={true}
                onChange={() => []}
              />
            ))}
          </div>
          <WithdrawalOverview
            withdrawAmount={withdrawAmount}
            withdrawalFee={withdrawalFee}
            withdrawalChargePercentage={kroFee?.percentage?.toString() ?? ""}
          />
          <KroButton onclick={handleWithdrawal} title="Continue"></KroButton>
        </div>
      )}
    </div>
  );
};

export default WithdrawalDetails;
