import { FC } from "react";
import { Helmet } from "react-helmet";

const KroMetaDecorator: FC<MetaDecoratorProps> = ({
  title,
  description,
  imageUrl,
  imageAlt,
}) => {
  const url = new URL(window.location.href);
  const cleanUrl = `${url.origin}${url.pathname}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={cleanUrl} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={imageAlt} />
      <meta name="twitter:site" content={""} />
    </Helmet>
  );
};

interface MetaDecoratorProps {
  title: string;
  description: string;
  imageUrl: string;
  imageAlt: string;
}

export default KroMetaDecorator;
