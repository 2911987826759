import KroBackButton from "features/components/buttons/kroBackButton";
import { useEffect, useState } from "react";

import moment from "moment";
import KroContainer from "features/components/display/kroContainer";
import { useKroFee } from "../hooks/useKroFee";
import KroModal from "features/components/modals/kroModal";
import { PreviewCampaignModalPage } from "./campaignPreviewPage";
import { KroButton } from "features/components/buttons/kroButton";
import { useUpdateCampaignFundraiserDetails } from "../hooks/useUpdateCampaignFundraiserDetails";
import FundraiserPublishedModal from "../components/campaignCreate/successBottomSheetContent";
import KroLoadingIndicator from "features/components/loader/kroCircularIndicator";
import { useCreateCampaignForm } from "../context/CreateCampaignFormProvider";
import { useForm } from "@tanstack/react-form";

import { UpdateCampaignTargetAmountPayload } from "features/campaign/domain/models/updateCampaignTargetAmountPayload";
import { UpdateCampaignDurationPayload } from "features/campaign/domain/models/updateCampaignDurationPayload";
import SupportingDocuments from "../components/campaignCreate/supportingDocuments";
import DurationSelector from "../components/campaignCreate/campaignDuration";
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "router/routes";
import CampaignCounterComponent from "../components/campaignCreate/campaignPageCounterComponent";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { useCampaignVideoUpload } from "../context/uploadVideoContext";
import FundraiserTarget from "../components/campaignCreate/fundraiserTarget";
import { toast } from "react-toastify";

const CampaignFundraiserTargetPage = () => {
  const { formData, setFormData, validateStepAccess } = useCreateCampaignForm();
  const { isLoading: isKroFeeLoading, data, error } = useKroFee();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showFundraiserPublished, setShowFundraiserPublished] =
    useState<boolean>(false);
  // const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { isVideoUploading } = useCampaignVideoUpload();

  const location = useLocation();
  useEffect(() => {
    validateStepAccess(location.pathname);
  }, [location, validateStepAccess]);

  const campaignlink = `${window.location.origin}/campaign/${formData.id}`;

  const form = useForm({
    defaultValues: {
      targetAmount: formData.targetAmount ?? "",
      amountToReceive: formData.amountToReceive ?? "",
      hasNoTarget: formData.hasNoTarget ?? false,
      endDate: "",
      supportingDocument: null as File | null,
    },
    onSubmit: (values) => {
      if (isVideoUploading) {
        toast.info("Please wait for the video to finish uploading");
        return;
      }
      const payload = {
        fundingPayload: {
          id: formData.id,
          amount: values.value.hasNoTarget
            ? 0
            : parseFloat(values.value.targetAmount),
        },
        durationPayload: {
          id: formData.id,
          startDate: moment().format("YYYY-MM-DD"),
          endDate: values.value.endDate,
        },
        files: values.value.supportingDocument
          ? [values.value.supportingDocument]
          : [],
      };
      mutation.mutate({
        fundingPayload: payload.fundingPayload,
        durationPayload: payload.durationPayload,
        files: payload.files,
      });
    },
  });

  const mutation = useUpdateCampaignFundraiserDetails(
    (error: Error) => {
      toast.error(error.message);
    },
    (
      data: CampaignModelMapper,
      variables: {
        fundingPayload: UpdateCampaignTargetAmountPayload;
        durationPayload: UpdateCampaignDurationPayload;
        files: File[];
      },
    ) => {
      setFormData((prevData) => ({
        ...prevData,
        hasNoTarget: form.state.values.hasNoTarget,
      }));
      handleOpenPreviewPageModal();
    },
  );

  const handleOpenPreviewPageModal = () => {
    if (mutation.data === null) return;
    setShowPreview(true);
  };
  const handleClosePreviewPageModal = (isPublish?: boolean) => {
    setShowPreview(false);
    if (isPublish) {
      handleOpenFundraiserPublishModal();
    }
  };

  const handleOpenFundraiserPublishModal = () => {
    setShowFundraiserPublished(true);
  };
  const handleCloseFundraiserPublishModal = () => {
    setShowFundraiserPublished(false);
    setFormData({});
    if (campaignlink) {
      window.location.href = campaignlink;
    }
  };

  if (error) {
    return (
      <KroLoadingIndicator
        error={error?.message}
        onRetry={() => window.location.reload()}
      />
    );
  }

  return (
    <>
      <KroModal
        onClose={() => handleClosePreviewPageModal()}
        isOpen={showPreview}
        className="px-0 py-0"
        maxWidth={1125}
      >
        <PreviewCampaignModalPage
          data={mutation.data!}
          onClose={handleClosePreviewPageModal}
        />
      </KroModal>

      <KroModal
        lottieAnimation={true}
        onClose={() => handleCloseFundraiserPublishModal()}
        isOpen={showFundraiserPublished}
        className="px-0 py-0"
        showCloseButton={false}
        maxWidth={411}
      >
        <FundraiserPublishedModal campaignId={formData.id} url={campaignlink} />
      </KroModal>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="mt-3 flex w-full max-w-[606px] flex-col items-center md:mt-[120px] lg:mt-[120px] lg:items-start"
      >
        <div className="flex w-full max-w-[500px] flex-col items-center">
          <CampaignCounterComponent
            pageCount={4}
            showSkip={false}
          ></CampaignCounterComponent>
          <KroContainer
            isLoading={isKroFeeLoading}
            margin="mb-6 mt-[12px]"
            title={"Fundraiser target"}
          >
            <FundraiserTarget kroFee={data} form={form} />
          </KroContainer>
          <KroContainer isLoading={isKroFeeLoading}>
            <DurationSelector form={form} />
            <SupportingDocuments form={form} />
          </KroContainer>
        </div>
        <div className="flex h-auto w-full flex-col-reverse justify-between gap-3 py-[51px] md:flex-row">
          <KroBackButton
            onclick={() => {
              if (window.history.length > 1) {
                navigate(-1);
              } else {
                navigate(Routes.CREATE_CAMPAIGN_GALLERY);
              }
            }}
          />
          <KroButton
            onclick={form.handleSubmit}
            title="Preview Fundraiser"
            isDisabled={isKroFeeLoading}
            isLoading={mutation.isPending}
          />
        </div>
      </form>
    </>
  );
};
export default CampaignFundraiserTargetPage;
