import { useMutation } from "@tanstack/react-query";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";
import { SayThanksPayload } from "../../domain/model/sayThanksPayload";
import { SayThanksResponseMapper } from "../../domain/model/sayThanksResonse";

const DashboardService = new DashboardServiceImpl();

const useSayThanks = (
  onError:
    | ((
        error: Error,
        variables: SayThanksPayload,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
  onSuccess:
    | ((
        data: SayThanksResponseMapper,
        variables: SayThanksPayload,
        context: unknown,
      ) => Promise<SayThanksResponseMapper> | unknown)
    | undefined,
) => {
  return useMutation({
    mutationFn: (payload: SayThanksPayload) =>
      DashboardService.sayThanks(payload),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export default useSayThanks;
